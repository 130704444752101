import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "assets/css/jubileusz.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core componentsxs
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";

import pStyles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import bStyles from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";
import setVideo from "utils/setVideo";
import setBanner from "utils/setBanner";
import setSlogan from "utils/setSlogan";
import { animateScroll as scroll } from "react-scroll";
import { applyHiddenAnimation } from "utils/applyHiddenAnimation";
import banner from "assets/img/banner/chorwacja.jpg";
import Box from "@material-ui/core/Box";
import Button from "../../../../components/CustomButtons/Button";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import GridItem from "../../../../components/Grid/GridItem";
import GridContainer from "../../../../components/Grid/GridContainer";

import logo from "assets/img/banner/jubileusz.png";

const styles = {
  ...pStyles,
  ...bStyles,
};

const useStyles = makeStyles(styles);

const offers = [
  {
    group: "Pakiety nurkowe",
    items: [
      {
        name: "TN10 (5 dni - 10 nurkowań)",
        regularPrice: 330,
        jubileePrice: 250,
      },
      {
        name: "TN8 (4 dni - 8 nurkowań)",
        regularPrice: 290,
        jubileePrice: 220,
      },
      {
        name: "TN6 (3 dni - 6 nurkowań)",
        regularPrice: 240,
        jubileePrice: 180,
      },
    ],
  },
  {
    group: "Kursy",
    items: [
      {
        name: "OWD SSI/PADI",
        regularPrice: 400,
        jubileePrice: 300,
      },
      {
        name: "AOWD SSI/PADI",
        regularPrice: 300,
        jubileePrice: 230,
      },
      {
        name: "Specjalizacjie Deep / Wreck",
        regularPrice: 150,
        jubileePrice: 110,
      },
    ],
  },
  {
    group: "Wypożyczenie sprzętu",
    items: [
      {
        name: "Komplet (1 dzień)",
        regularPrice: 30,
        jubileePrice: 20,
      },
      {
        name: "Komplet (5 dni)",
        regularPrice: 120,
        jubileePrice: 90,
      },
    ],
  },
];

const Offer25Years = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    setVideo(false);
    setBanner(banner);
    setSlogan("Oferta Jubileuszowa", "25% rabatu na 25-lecie Nautilusa");
    scroll.scrollToTop();
    return applyHiddenAnimation();
  }, []);

  function handleBookClicked() {
    navigate("/pl/jubileusz/rezerwuj");
  }

  return (
    <div className={classes.section}>
      <Zoom in>
        <Box
          cs={12}
          sm={12}
          md={8}
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "1rem",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            margin: "4rem 0",
          }}
        >
          <div className="jubileusz-header">
            <img
              src={logo}
              alt="jubileusz 25 lat Nautilusa"
              width={80}
              height={80}
              style={{ marginRight: "1rem" }}
            />

            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                alignItems: "flex-start",
                justifyItems: "center",
                height: "100%",
              }}
            >
              <Typography variant="h4" className={classes.description}>
                Oferta jubileuszowa - Chorwacja 2024
              </Typography>

              <Typography variant="body1" className={classes.description}>
                Dotyczy pobytów w Chorwacji od 1 maja do 15 października 2024
              </Typography>
            </Box>
          </div>

          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "1rem",
              width: "100%",
            }}
          >
            {offers.map((offer, index) => (
              <TableContainer
                key={offer.group}
                component={Paper}
                style={{
                  boxShadow: "none",
                  width: "100%",
                  marginTop: "2rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <Table
                  sx={{
                    minWidth: 650,
                    boxShadow: "none",
                    overflowX: "scroll",
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: "bold" }}
                        >
                          {offer.group}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: "bold" }}
                        >
                          Cena regularna
                        </Typography>
                        <Typography
                          variant="caption"
                          style={{ fontWeight: "bold" }}
                        >
                          najniższa cena z ostatnich 30 dni
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: "bold" }}
                        >
                          Cena jubileuszowa
                        </Typography>
                        <Typography
                          variant="caption"
                          style={{ fontWeight: "bold" }}
                        >
                          zniżka 25%
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {offer.items.map((rowContent) => (
                      <TableRow
                        key={rowContent.name}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <Typography
                            variant="body2"
                            style={{ fontWeight: "normal" }}
                          >
                            {rowContent.name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body2"
                            style={{
                              fontWeight: "normal",
                              textDecoration: "line-through",
                            }}
                          >
                            {rowContent.regularPrice} Euro
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body2"
                            style={{ fontWeight: "bold", color: "red" }}
                          >
                            {rowContent.jubileePrice} Euro
                          </Typography>

                          <Typography variant="caption">
                            oszczędzasz{" "}
                            {rowContent.regularPrice - rowContent.jubileePrice}{" "}
                            Euro
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {index === 1 && (
                  <Typography
                    variant="caption"
                    className={classes.description}
                    style={{ marginTop: "1rem" }}
                  >
                    * Zniżka nie dotyczy opłaty za certifikat i materiały
                    szkoleniowe.
                  </Typography>
                )}

                {index === 0 && (
                  <Typography
                    variant="caption"
                    className={classes.description}
                    style={{ marginTop: "1rem" }}
                  >
                    * Cena za pakiet nurkowy zawiera: butlę 12l, balast, opiekę
                    przewodników nurkowych, przekąski lub owoce, briefing i
                    świetną atmosferę na łodzi.
                  </Typography>
                )}
              </TableContainer>
            ))}
          </Box>

          <GridContainer
            justifyContent="flex-start"
            style={{
              width: "100%",
              marginTop: "4rem",
              textAlign: "left",
              gap: "1rem",
            }}
          >
            <GridItem xs={12} sm={12} md={12} className="hidden">
              <Typography
                variant="body2"
                className={classes.description}
                style={{ fontWeight: "bolder" }}
              >
                Zniżka obowiązuje przy rezerwacji oraz wpłacie zaliczki w
                wysokości 40% wartości pakietu do 15 stycznia 2024
              </Typography>
            </GridItem>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              className="hidden"
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <Button
                onClick={handleBookClicked}
                color="info"
                style={{ width: "50%", marginBottom: "1rem" }}
              >
                Rezerwuj
              </Button>
            </GridItem>
          </GridContainer>
        </Box>
      </Zoom>
    </div>
  );
};

export default Offer25Years;
