import React from "react";
import PropTypes from "prop-types";
import { Link as ScrollLink } from "react-scroll";
import { Fab } from "@material-ui/core";
import { useSoftRiseShadowStyles } from "@mui-treasury/styles/shadow/softRise";
import cx from "clsx";

const ScrollButton = (props) => {
  const shadowStyles = useSoftRiseShadowStyles();

  let style = {
    color: "white",
    backgroundColor: "#45aec3",
    opacity: "0.7",
    marginRight: "10px",
    border: "2px",
  };

  if (props.strong) {
    style = {
      color: "white",
      backgroundColor: "#3ce034",
      opacity: "0.7",
      marginRight: "10px",
      border: "2px",
    };
  }

  return (
    <ScrollLink
      to={props.target}
      spy={true}
      smooth={true}
      offset={Number(props.offset)}
      duration={500}
    >
      <Fab
        style={style}
        className={cx(shadowStyles.root)}
        size="small"
        variant="extended"
        onClick={props.onClick}
      >
        {props.target}
      </Fab>
    </ScrollLink>
  );
};

ScrollButton.propTypes = {
  strong: PropTypes.bool,
  target: PropTypes.string,
  offset: PropTypes.string,
  onClick: PropTypes.func,
};

export default ScrollButton;
