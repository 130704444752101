import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import setVideo from "../../../../utils/setVideo";
import setBanner from "../../../../utils/setBanner";
import blogImage from "../../../../assets/img/banner/blog.jpg";
import setSlogan from "../../../../utils/setSlogan";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useCMS } from "../../../../utils/useCMS";
import Helmet from "react-helmet";
import { Text } from "../../../../components/Text/Text";
import Box from "@material-ui/core/Box";
import { Video } from "../../../../components/Video/Video";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../../assets/jss/material-kit-react/views/landingPageSections/productStyle";
import { Image } from "../../../../components/Image/Image";
import BlogCard from "../../../../components/BlogCard/BlogCardMUIT";
import { useParams } from "react-router-dom";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(styles);

export const BlogEntry = memo((props) => {
  const blog = useSelector((state) => state.blog.blog);
  const [headerPhoto, setHeaderPhoto] = useState(null);
  const { getImageUrl } = useCMS();
  const { language } = useParams();

  const blogEntry = useMemo(
    () => blog.find((entry) => entry.firebaseId === props.blogId),
    [blog, props.blogId]
  );

  useEffect(() => {
    if (blogEntry.headerPhoto) {
      getImageUrl(blogEntry.headerPhoto).then((imageUrl) =>
        setHeaderPhoto(imageUrl)
      );
    }
  }, [blogEntry, getImageUrl]);

  useEffect(() => {
    setVideo(false);
    setBanner(headerPhoto || blogImage);
    setSlogan(blogEntry.title, blogEntry.author + " | " + blogEntry.created_on);
  }, [headerPhoto, blogEntry]);

  const handleImageClick = useCallback((image) => {
    const imageElement = document.getElementById(image);
    const imageContainerElement = imageElement.parentElement;
    imageContainerElement.classList.add("hidden");
    imageElement.classList.add("hidden");
    const imageSrc = imageElement.src;
    const imageAlt = imageElement.alt;

    const imageContainer = document.createElement("img");
    imageContainer.id = "large-image";
    imageContainer.src = imageSrc;
    imageContainer.alt = imageAlt;

    setTimeout(() => {
      imageContainer.classList.add("active");
    }, 0);

    imageContainer.addEventListener("click", () => {
      imageContainer.classList.remove("active");
      imageElement.classList.remove("hidden");
      imageContainerElement.classList.remove("hidden");
      setTimeout(() => {
        imageContainer.remove();
      }, 500);
    });
    document.body.appendChild(imageContainer);
  }, []);

  const classes = useStyles();

  let content = null;
  if (blogEntry.content) {
    content = blogEntry.content.map((item) => {
      if (item.type === "text") {
        return (
          <Text
            markdown={item.value}
            key={item.value}
            className={classes.description}
          />
        );
      } else if (item.type === "images") {
        return (
          <Box
            sx={{
              display: "grid",
              gap: "1rem",
              gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
              width: "100%",
            }}
            key={item.value[0]}
          >
            {item.value.map((image) => {
              return (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignContent: "flex-start",
                  }}
                  key={image}
                >
                  <Image
                    image={image}
                    id={image}
                    alt="post image"
                    imageClassName="gallery-image post-image"
                    className="gallery-image-container"
                    onClick={() => handleImageClick(image)}
                  />
                </Box>
              );
            })}
          </Box>
        );
      } else if (item.type === "video") {
        return (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignContent: "flex-start",
            }}
            key={item.value}
          >
            {item.value.map((video) => (
              <Video src={video} key={video} />
            ))}
          </Box>
        );
      }
    });
  }

  const filtrator = useCallback(
    (blogEntry) => {
      return blogEntry.firebaseId !== props.blogId;
    },
    [props.blogId]
  );

  if (!blogEntry || !blogEntry.content) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{`Nautilus - ${blogEntry.title}`}</title>
        <meta name="description" content={blogEntry.seoDescription} />
        <meta name="robots" content="" />
      </Helmet>
      <Box className="responsive-flex">
        <Box
          className={classes.section}
          style={{
            maxWidth: "700px",
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            padding: "40px 0",
          }}
        >
          {content}
        </Box>

        <Box
          className={classes.section}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            justifyContent: "flex-end",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Typography variant="h4" className={classes.description}>
            Najnowsze wpisy
          </Typography>
          {blog.length &&
            blog
              .filter(filtrator)
              .slice(0, 3)
              .map((blogEntry) => (
                <BlogCard
                  key={blogEntry.firebaseId}
                  itemProp="blogPost"
                  cmsImage={blogEntry.headerPhoto}
                  author={blogEntry.author}
                  title={blogEntry.title}
                  date={blogEntry.created_on}
                  description={blogEntry.seoDescription}
                  path={`/${language}/blog/${blogEntry.firebaseId}`}
                />
              ))}
        </Box>
      </Box>
    </>
  );
});

BlogEntry.displayName = "BlogEntry";

BlogEntry.propTypes = {
  blogId: PropTypes.string,
};
