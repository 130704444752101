export async function registerPeriodicNewsCheck() {
  const registration = await navigator.serviceWorker.ready;
  try {
    await registration.periodicSync.register("get-latest-news", {
      minInterval: 24 * 60 * 60 * 1000,
    });
  } catch (error) {
    console.log("Periodic Sync could not be registered!");
    console.error(error);
  }
}

const register = (pathToWorker, onInstall, onUpdate, onError) => {
  if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      return;
    }

    window.addEventListener("load", async () => {
      try {
        const registration = await navigator.serviceWorker.register(
          process.env.PUBLIC_URL + pathToWorker
        );
        registration.onupdatefound = () => {
          const worker = registration.installing;
          if (worker) {
            worker.onstatechange = () => {
              if (worker.state === "installed") {
                if (navigator.serviceWorker.controller) {
                  if (onUpdate) {
                    onUpdate(registration);
                  }
                } else {
                  if (onInstall) {
                    onInstall(registration);
                  }
                }
              }
            };
          }
        };
      } catch (err) {
        if (onError) {
          onError(err);
        }
      }
    });
  }
};

export const registerWorker = () =>
  register(
    "/serviceWorker.js",
    (reg) => console.info("ServiceWorker has been installed", reg),
    (reg) => {
      console.info("ServiceWorker has been updated", reg);
      reg.waiting.postMessage({ type: "SKIP_WAITING" });
    },
    (err) => console.error("Error in ServiceWorker", err)
  );
