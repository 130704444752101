import React, { useContext } from "react";
import { useParams } from "react-router-dom";
// @material-ui/core components
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { animateScroll as scroll } from "react-scroll";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// custom components
import OfferCard from "components/OfferCard/OfferCard.js";
import { TextContext } from "context/TextProvider";

// @material-ui styles
import { cardTitle } from "assets/jss/material-kit-react.js";
import pStyles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

// pictures
import kornati from "assets/img/navimages/kornati.jpg";
import kurs from "assets/img/navimages/kurs.jpg";
import kamczatka from "assets/img/navimages/kamczatka.jpg";
import cuba from "assets/img/navimages/cuba_flag.jpg";
import Splitter from "../../../../components/Splitter/Splitter";
import Box from "@material-ui/core/Box";

const styles = {
  ...pStyles,
  cardTitle,
};

const useStyles = makeStyles(styles);

export default function OfferSection() {
  const dictionary = useContext(TextContext);
  const classes = useStyles();
  const navigate = useNavigate();
  const { language } = useParams();

  function selectionMade(e, path) {
    e.preventDefault();
    scroll.scrollTo(330);
    navigate(path);
  }

  return (
    <div
      className={classes.section}
      itemScope
      itemType="https://schema.org/LocalBusiness"
    >
      <Splitter />

      <Box className="left">
        <Typography
          variant="h4"
          className={`${classes.description} hidden`}
          style={{ fontWeight: "600" }}
        >
          {dictionary.offerSection.title}
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.description} hidden`}
          style={{ marginTop: "20px" }}
        >
          {dictionary.offerSection.text1}
          <br />
          {dictionary.offerSection.text2}
          <br />
          {dictionary.offerSection.text3}
        </Typography>
      </Box>

      <div>
        <GridContainer
          className="delayed"
          style={{
            alignItems: "flex-start",
            justifyContent: "flex-start",
            paddingLeft: "15px",
          }}
        >
          <GridItem
            xs={12}
            sm={12}
            md={3}
            className="hidden"
            style={{ padding: 0 }}
          >
            <OfferCard
              image={kornati}
              title={dictionary.offerSection.croatiaCard.title}
              description={dictionary.offerSection.croatiaCard.text}
              buttonClickedHandler={(e) =>
                selectionMade(e, `/${language}/croatia`)
              }
            />
          </GridItem>

          <GridItem
            xs={12}
            sm={12}
            md={3}
            className="hidden"
            style={{ padding: 0 }}
          >
            <OfferCard
              image={kurs}
              title={dictionary.offerSection.coursesCard.title}
              description={dictionary.offerSection.coursesCard.text}
              buttonClickedHandler={(e) =>
                selectionMade(e, `/${language}/courses`)
              }
            />
          </GridItem>

          <GridItem
            xs={12}
            sm={12}
            md={3}
            className="hidden"
            style={{ padding: 0 }}
          >
            <OfferCard
              image={kamczatka}
              title={dictionary.offerSection.eventsCard.title}
              description={dictionary.offerSection.eventsCard.text}
              buttonClickedHandler={(e) =>
                selectionMade(e, `/${language}/trips`)
              }
            />
          </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={3}
            className="hidden"
            style={{ padding: 0 }}
          >
            <OfferCard
              image={cuba}
              title={dictionary.offerSection.cubaCard.title}
              description={dictionary.offerSection.cubaCard.text}
              buttonClickedHandler={(e) => selectionMade(e, "/pl/cuba")}
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
