import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import useSeo from "utils/useSeo";

export default function MetaSEO(props) {
  const seo = useSeo(props.url);

  let meta = null;
  if (seo) {
    meta = (
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta name="robots" content={seo.robots} />
        <link rel="canonical" href={seo.canonical} />
      </Helmet>
    );
  }
  return meta;
}

MetaSEO.propTypes = {
  url: PropTypes.string,
};
