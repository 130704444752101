import { title, cardTitle } from "assets/jss/material-kit-react.js";
import imagesStyle from "assets/jss/material-kit-react/imagesStyles.js";

const productStyle = {
  section: {
    padding: "20px 0",
    textAlign: "center",
  },

  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    color: "#333333",
  },

  header1: {
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    fontSize: "3.7em",
    color: "#333333",
  },

  header2: {
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    fontSize: "3em",
    color: "#333333",
  },

  header3: {
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    fontSize: "2em",
    color: "#333333",
  },

  description: {
    color: "#333333",
  },

  gridList: {
    height: 450,
  },

  textBlock: {
    textAlign: "justify",
    color: "#333333",
  },

  ...imagesStyle,

  itemGrid: {
    marginLeft: "auto",
    marginRight: "auto",
  },

  cardTitle,

  smallTitle: {
    color: "#6c757d",
  },

  justifyCenter: {
    justifyContent: "center !important",
  },

  socials: {
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: "#999",
  },

  margin5: {
    margin: "5px",
  },

  root: {
    maxWidth: 304,
    margin: "auto",
    boxShadow: "none",
    borderRadius: 0,
  },
};

export default productStyle;
