import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import { Typography } from "@material-ui/core";

import imagesStyles from "assets/jss/material-kit-react/imagesStyles.js";
import { cardTitle } from "assets/jss/material-kit-react.js";
import pStyles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import { useSoftRiseShadowStyles } from "@mui-treasury/styles/shadow/softRise";

const styles = {
  ...imagesStyles,
  ...pStyles,
  cardTitle,
};

const useStyles = makeStyles(styles);

const OfferCard = (props) => {
  const classes = useStyles();
  const shadowStyles = useSoftRiseShadowStyles();

  return (
    <Card
      style={{
        maxWidth: "90%",
        width: "20rem",
        minHeight: "530px",
        position: "relative",
      }}
      className={shadowStyles.root}
      onClick={(e) => props.buttonClickedHandler(e)}
    >
      <img
        style={{ height: "150px", width: "100%", display: "block" }}
        className={classes.imgCardTop}
        src={props.image}
        alt="Kornaty"
      />
      <CardBody>
        <Typography
          variant="h6"
          className={classes.description}
          style={{ textAlign: "left" }}
        >
          {props.title}
        </Typography>

        <Typography
          variant="body2"
          className={classes.textBlock}
          style={{ marginTop: "10px", marginBottom: "15px", textAlign: "left" }}
        >
          {props.description}
        </Typography>

        <Button
          color="transparent"
          style={{
            border: "2px solid #00acc1",
            position: "absolute",
            bottom: "20px",
            left: "30px",
          }}
        >
          <Typography variant="button">Dowiedz się więcej</Typography>
        </Button>
      </CardBody>
    </Card>
  );
};

OfferCard.propTypes = {
  buttonClickedHandler: PropTypes.func,
  image: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default OfferCard;
