import React from "react";
import "@lottiefiles/lottie-player";

const Splitter = () => {
  return (
    <div
      style={{
        position: "relative",
        marginBottom: "50px",
        paddingRight: "10px",
      }}
    >
      <lottie-player
        autoplay
        loop
        speed={1}
        intermission={10000}
        mode="normal"
        src="https://assets9.lottiefiles.com/packages/lf20_UeARny.json"
        style={{
          position: "absolute",
          bottom: "0",
          right: "20px",
          width: "120px",
          height: "120px",
          zIndex: "100",
          opacity: "0.8",
        }}
      />
      <hr />
    </div>
  );
};

export default Splitter;
