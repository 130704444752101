import React, { useEffect, useContext } from "react";
import { useSelector } from "react-redux";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import pStyles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import ParallaxSlide from "components/NewsParalax/ParallaxSlide";
import DotIndicator from "@mui-treasury/components/indicator/dot";
import { useArrowDarkButtonStyles } from "@mui-treasury/styles/button/arrowDark";
import setNews from "utils/setNews";
import NewsCard from "components/NewsCard/NewsCard";
import { TextContext } from "context/TextProvider";
import Splitter from "../../../../components/Splitter/Splitter";

const usePStyles = makeStyles(pStyles);
/* eslint-disable */
const useStyles = makeStyles(({ palette, breakpoints, spacing }) => {
  return {
    arrow: {
      display: "none",
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      [breakpoints.up("sm")]: {
        display: "inline-flex",
      },
    },
    arrowLeft: {
      left: 0,
      [breakpoints.up("lg")]: {
        left: -64,
      },
    },
    arrowRight: {
      right: 0,
      [breakpoints.up("lg")]: {
        right: -64,
      },
    },
    indicatorContainer: {
      textAlign: "center",
    },
  };
});

const NewsComponent = () => {
  const dictionary = useContext(TextContext);
  let news = useSelector((state) => state.news.news);
  const classes = useStyles();
  const pClasses = usePStyles();
  const arrowStyles = useArrowDarkButtonStyles();

  useEffect(() => {
    if (!news && dictionary.news) {
      const results = dictionary.news.filter((element) => {
        if (element && Object.keys(element).length !== 0) {
          return true;
        }
        return false;
      });
      setNews(results);
    }
  });

  let newsElements = [];
  if (news) {
    newsElements = Object.keys(news).map((id) => {
      if (news[id]) {
        return (
          <NewsCard
            date={news[id].data}
            image={news[id].obraz}
            title={news[id].tytuł}
            body={news[id].opis}
            link={news[id].link}
            key={id}
          />
        );
      } else {
        return null;
      }
    });
  }

  const renderChildren = ({ injectStyle, fineIndex }) => {
    injectStyle(fineIndex, 2000);
    return newsElements;
  };

  const renderElements = ({ index, onChangeIndex }) => {
    setTimeout(() => {
      if (index < news.length - 1) {
        onChangeIndex(index + 1);
      } else {
        onChangeIndex(0);
      }
    }, 5000);

    return (
      <>
        <Button
          className={cx(classes.arrow, classes.arrowLeft)}
          classes={arrowStyles}
          disabled={index === 0}
          onClick={() => onChangeIndex(index - 1)}
        >
          <KeyboardArrowLeft />
        </Button>
        <Button
          className={cx(classes.arrow, classes.arrowRight)}
          classes={arrowStyles}
          disabled={index === news.length - 1}
          onClick={() => onChangeIndex(index + 1)}
        >
          <KeyboardArrowRight />
        </Button>
        <div className={classes.indicatorContainer}>
          {news.map(({ id }, i) => {
            console.log(id);
            return (
              <DotIndicator
                key={i}
                active={i === index}
                onClick={() => onChangeIndex(i)}
              />
            );
          })}
        </div>
      </>
    );
  };

  return news ? (
    <div className={pClasses.section}>
      <Splitter />
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={12}>
          <Typography variant="h4" className={pClasses.description}>
            {dictionary.newsSection.title}
          </Typography>
          <br></br>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <ParallaxSlide
            renderElements={renderElements}
            size={newsElements.length}
          >
            {renderChildren}
          </ParallaxSlide>
        </GridItem>
      </GridContainer>
    </div>
  ) : null;
};

export default NewsComponent;
