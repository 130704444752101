import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { getDownloadURL, getStorage, ref } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.FIRESTORE_API_KEY,
  authDomain: "nautilus-admin.firebaseapp.com",
  projectId: "nautilus-admin",
  storageBucket: "nautilus-admin.appspot.com",
  messagingSenderId: "362381792552",
  appId: "1:362381792552:web:aa8dd323ac97095c4a6c1d",
};

export const useCMS = () => {
  const app = initializeApp(firebaseConfig, "CMS");
  const db = getFirestore(app);
  async function getCollectionFromCMS(collectionName) {
    return await getDocs(collection(db, collectionName)).then(
      (querySnapshot) => {
        return querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          firebaseId: doc.id,
        }));
      }
    );
  }

  async function getImageUrl(imagePath) {
    const storage = getStorage(app);
    const storageRef = ref(storage, imagePath);
    return await getDownloadURL(storageRef);
  }

  return { getCollectionFromCMS, getImageUrl };
};
