import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { Typography } from "@material-ui/core";

import { TextContext } from "context/TextProvider";
import logo from "assets/img/logo.jpeg";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import Box from "@material-ui/core/Box";

const useStyle = makeStyles(styles);

const LogoSection = () => {
  const dictionary = useContext(TextContext);
  const classes = useStyle();
  const h1 = useSelector((state) => state.h1.h1);

  return (
    <div className={classes.section}>
      <Box className="logo-section">
        <Box className="hidden logo-section-logo">
          <img itemProp="logo" src={logo} alt="logo" />
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "18px",
          }}
        >
          <Box className="hidden">
            <Typography
              variant="h1"
              className={classes.header1}
              style={{
                fontWeight: 600,
                textAlign: "left",
                paddingTop: "0",
                marginTop: "10px",
              }}
            >
              {h1}
            </Typography>
          </Box>

          <Box className="hidden">
            <Typography
              variant="h5"
              className={classes.description}
              style={{
                textAlign: "left",
                marginBottom: "0",
                paddingBottom: "0",
              }}
            >
              {dictionary.logoSection.text1}
            </Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default LogoSection;
