import React, { memo } from "react";
import PropTypes from "prop-types";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EuroIcon from "@material-ui/icons/Euro";

import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles(styles);

const sorter = (a, b) => {
  const aDate = new Date(a.split("-")[0].split(".").reverse().join("."));
  const bDate = new Date(b.split("-")[0].split(".").reverse().join("."));

  if (aDate > bDate) {
    return 1;
  }

  if (aDate < bDate) {
    return -1;
  }

  return 0;
};

export const PriceList = memo((props) => {
  const classes = useStyles();

  const { priceList } = props;

  if (!priceList) {
    return null;
  }

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 0,
        border: "1px solid #e0e0e0",
        borderRadius: "4px",
        padding: "1rem",
        alignItems: "flex-start",
        justifyContent: "flex-start",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "flex-start",
        }}
      >
        <EuroIcon
          style={{ marginRight: "0.5rem", height: "1.5rem" }}
          className={classes.description}
        />
        <Typography
          variant="h5"
          className={classes.description}
          style={{
            marginBottom: "2rem",
            fontWeight: "bold",
            fontSize: "1.2rem",
          }}
        >
          Wybierz okres aby sprawdzić ceny apartamentów za dobę
        </Typography>
      </Box>

      {Object.keys(priceList)
        .filter((period) => !!priceList[period].length)
        .sort(sorter)
        .map((period) => (
          <Accordion key={period} style={{ boxShadow: "none", width: "100%" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6" className={classes.description}>
                {period.split("-").join(" - ")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  width: "100%",
                  alignItems: "flex-start",
                }}
              >
                <TableContainer
                  component={Paper}
                  style={{ boxShadow: "none", width: "100%" }}
                >
                  <Table
                    sx={{
                      minWidth: 650,
                      boxShadow: "none",
                      overflowX: "scroll",
                    }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="body1"
                            style={{ fontWeight: "bold" }}
                          >
                            Ilość osób
                          </Typography>
                        </TableCell>
                        {priceList[period].map((price) => (
                          <TableCell key={price.capacity}>
                            <Typography
                              variant="body1"
                              style={{ fontWeight: "bold" }}
                            >
                              {price.capacity}
                            </Typography>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <Typography
                            variant="body2"
                            style={{ fontWeight: "normal" }}
                          >
                            Cena EUR
                          </Typography>
                        </TableCell>
                        {priceList[period].map((price) => (
                          <TableCell
                            component="th"
                            scope="row"
                            key={price.capacity}
                          >
                            <Typography
                              variant="body2"
                              style={{ fontWeight: "normal" }}
                            >
                              {price.price}
                            </Typography>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Typography variant="caption">
                  * Ceny za apartament za dobę. Cena zawiera opłatę klimatyczną
                  oraz opiekę polskiego rezydenta.
                </Typography>
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
    </Box>
  );
});

PriceList.displayName = "PriceList";

PriceList.propTypes = {
  priceList: PropTypes.object,
};
