import React, { forwardRef, memo } from "react";

import "./image.css";
import PropTypes from "prop-types";
import { useCMS } from "../../utils/useCMS";

export const Image = memo(
  forwardRef((props, ref) => {
    const { image, id, className, imageClassName, onClick, style } = props;
    const { getImageUrl } = useCMS();

    const [imageLoaded, setImageLoaded] = React.useState(false);
    const localRef = React.useRef();
    ref = ref || localRef;

    const [url, setUrl] = React.useState(null);

    React.useEffect(() => {
      if (image) {
        getImageUrl(image.src || image).then((url) => setUrl(url));
      }
    }, [image, getImageUrl]);

    return (
      <div
        className={"image_block " + className}
        onClick={onClick}
        style={style}
      >
        {!imageLoaded && <div className="image_block_loader" />}
        <img
          id={id}
          ref={ref}
          className={"section_image " + imageClassName}
          src={url}
          alt={image.alt}
          onLoad={() => setImageLoaded(true)}
          loading="lazy"
        />
      </div>
    );
  })
);

Image.displayName = "Image";

Image.propTypes = {
  className: PropTypes.string,
  imageClassName: PropTypes.string,
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
    }),
  ]),
  id: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
};
