import React, { memo, useMemo } from "react";
import { Typography } from "@material-ui/core";
import { Converter } from "showdown";

// eslint-disable-next-line react/prop-types
export const Text = memo(({ markdown, id, className }) => {
  const converter = useMemo(() => new Converter(), []);

  return (
    <Typography
      variant="body1"
      sx={{ marginBottom: "2rem" }}
      align="left"
      className={className}
      id={id}
    >
      <span
        // eslint-disable-next-line react-security/no-dangerously-set-innerhtml
        dangerouslySetInnerHTML={{
          __html: converter.makeHtml(markdown),
        }}
      />
    </Typography>
  );
});

Text.displayName = "Text";
