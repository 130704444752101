import React, { useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { Element } from "react-scroll";

import OfferSection from "./OfferSection/OfferSection.js";
import Calendarium from "./Calendarium/Calendarium.js";
import ScrollPanel from "components/ScrollPanel/ScrollPanelMain.js";
import { Zoom } from "@material-ui/core";
import MetaSEO from "components/MetaSEO/MetaSEO.js";

import banner from "assets/img/banner/chorwacja.jpg";
import setBanner from "utils/setBanner";
import setSlogan from "utils/setSlogan";
import setVideo from "utils/setVideo";
import LogoSection from "./LogoSection/LogoSection.js";
import NewsComponent from "./News/News.js";
import setH1 from "utils/setH1.js";
import { TextContext } from "context/TextProvider.js";

const FrontPage = (props) => {
  const dictionary = useContext(TextContext);

  useEffect(() => {
    setVideo(true);
    setBanner(banner);
    setSlogan(dictionary.frontPage.slogan1, dictionary.frontPage.slogan2);
    setH1(dictionary.frontPage.H1);
  });

  const events = useSelector((state) => state.events.events);

  return (
    <div>
      <MetaSEO url="/pl" />

      <ScrollPanel />

      <Zoom in>
        <Element name="logo">
          <LogoSection />
        </Element>
      </Zoom>

      <Zoom in>
        <Element name={dictionary.frontPage.newsButton}>
          <NewsComponent {...props} />
        </Element>
      </Zoom>

      <Zoom in>
        <Element name={dictionary.frontPage.offerButton}>
          <OfferSection {...props} />
        </Element>
      </Zoom>

      <Zoom in>
        <Element name={dictionary.frontPage.eventsButton}>
          <Calendarium {...props} events={events} />
        </Element>
      </Zoom>
    </div>
  );
};

export default FrontPage;
