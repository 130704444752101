import React, { useEffect, Suspense, lazy } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import setEvents from "utils/setEvents.js";
import { Oval } from "react-loader-spinner";

import beginners from "assets/img/banner/beginners.jpg";
import advanced from "assets/img/banner/advanced.jpg";
import specials from "assets/img/banner/specials.jpg";
import kids from "assets/img/banner/kids.jpg";
import MainPageWithTextProvider from "./MainPageWithTextProvider.js";
import FrontPage from "./Sections/FrontPage.js";
import { useCMS } from "../../utils/useCMS";
import setBlog from "../../utils/setBlog";
import { BlogEntry } from "./Sections/Blog/BlogEntry";
import ContactUs25Years from "./Sections/25years/25yearsContact";
import Offer25Years from "./Sections/25years/25yearsOffer";
import setApartments from "../../utils/setApartments";
import { ApartmentDetails } from "../../components/AppartmentsPrices/ApartmentDetails";
import setPeriods from "../../utils/setPeriods";

// Sections for this page
const AboutSection = lazy(() => import("./Sections/About/AboutSection.js"));
const ContactSection = lazy(() =>
  import("./Sections/Contact/ContactSection.js")
);

const CubaSection = lazy(() => import("./Sections/Cuba/CubaSection.js"));

const CroatiaSection = lazy(() =>
  import("./Sections/Croatia/CroatiaSection.js")
);
const TripsSection = lazy(() => import("./Sections/Trips/TripsSection.js"));
const CoursesSection = lazy(() =>
  import("./Sections/Courses/CoursesSection.js")
);
const CoursesDetails = lazy(() =>
  import("./Sections/Courses/CoursesDetails/CoursesDetails.js")
);
const GallerySection = lazy(() =>
  import("./Sections/Gallery/GallerySection.js")
);
const CroatiaGallery = lazy(() =>
  import("./Sections/Gallery/CroatiaGallery.js")
);

const GrenlandiaGallery = lazy(() =>
  import("./Sections/Gallery/GrenlandiaGallery.js")
);

const EgiptGallery = lazy(() => import("./Sections/Gallery/EgiptGallery.js"));
const KamczatkaGallery = lazy(() =>
  import("./Sections/Gallery/KamczatkaGallery.js")
);
const BarentsGallery = lazy(() =>
  import("./Sections/Gallery/BarentsGallery.js")
);

const Grenlandia = lazy(() =>
  import("./Sections/Trips/Grenlandia/Grenlandia.js")
);
const Filipiny = lazy(() => import("./Sections/Trips/Filipiny/Filipiny.js"));
const Malediwy = lazy(() => import("./Sections/Trips/Malediwy/Malediwy.js"));
const BajkalGallery = lazy(() => import("./Sections/Gallery/BajkalGallery.js"));
const OrdaGallery = lazy(() => import("./Sections/Gallery/OrdaGallery.js"));
const KubaGallery = lazy(() => import("./Sections/Gallery/KubaGallery.js"));
const MeksykGallery = lazy(() => import("./Sections/Gallery/MeksykGallery.js"));
const Blog = lazy(() => import("./Sections/Blog/Blog.js"));
// const Blog1 = lazy(() => import("./Sections/Blog/Blog1/Blog1.js"));
// const Blog2 = lazy(() => import("./Sections/Blog/Blog2/Blog2.js"));
const Blog3 = lazy(() => import("./Sections/Blog/Blog3/Blog3.js"));
const Event = lazy(() => import("./Sections/Event/Event.js"));
const DiveSites = lazy(() =>
  import("./Sections/Croatia/DiveSites/DiveSites.js")
);
const NautilusCenter = lazy(() =>
  import("./Sections/Croatia/NautilusCenter/NautilusCenter.js")
);
const PricesCroatia = lazy(() =>
  import("./Sections/Croatia/PricesCroatia/PricesCroatia.js")
);
const BookAppartment = lazy(() =>
  import("./Sections/Croatia/BookAppartment/BookAppartment.js")
);
const Kamczatka = lazy(() => import("./Sections/Trips/Kamczatka/Kamczatka.js"));
const Bajkal = lazy(() => import("./Sections/Trips/Bajkal/Bajkal.js"));
const Orda = lazy(() => import("./Sections/Trips/Orda/Orda.js"));
const Kuba = lazy(() => import("./Sections/Trips/Kuba/Kuba.js"));
const Meksyk = lazy(() => import("./Sections/Trips/Meksyk/Meksyk.js"));
const Barents = lazy(() => import("./Sections/Trips/Barents/Barents.js"));
const ProgramWidget = lazy(() =>
  import("components/ProgramWidget/ProgramWidget.js")
);
const DemandsWidget = lazy(() => import("components/DemandList/DemandList.js"));
const LocationsWidget = lazy(() =>
  import("components/LocationsList/LocationsList.js")
);
const PageNotFoundScreen = lazy(() => import("./PageNotFoundScreen.js"));

const spinner = (
  <div style={{ position: "fixed", left: "50%", top: "50%" }}>
    <Oval
      height={80}
      width={80}
      color="#00aac1"
      visible={true}
      secondaryColor="lightgrey"
      strokeWidth={4}
      strokeWidthSecondary={4}
    />
  </div>
);

export default function Routing(props) {
  const events = useSelector((state) => state.events.events);
  const blog = useSelector((state) => state.blog.blog);
  const apartments = useSelector((state) => state.apartments.apartments);
  const periods = useSelector((state) => state.periods.periods);
  const location = useLocation();
  const navigate = useNavigate();
  const { getCollectionFromCMS } = useCMS();

  const getPeriodsFromCMS = async () => {
    setPeriods(await getCollectionFromCMS("periods"));
  };

  const getEventsFromCMS = async () => {
    setEvents(await getCollectionFromCMS("events"));
  };

  const getBlogFromCMS = async () => {
    setBlog(await getCollectionFromCMS("blog"));
  };

  const getApartmentsFromCMS = async () => {
    const _apartments = await getCollectionFromCMS("appartments");

    setApartments(
      _apartments.map((a) => ({
        ...a,
        prices: [
          ...a.prices.map((p) => ({
            ...p,
            period: periods.find(
              (period) => period.firebaseId === p.period._key.path.segments[6]
            ).period,
          })),
        ],
      }))
    );
  };

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/pl");
    }
  }, [location]);

  useEffect(() => {
    if (!events) {
      getEventsFromCMS();
    }

    if (!blog) {
      getBlogFromCMS();
    }

    if (!periods) {
      getPeriodsFromCMS();
    }
  }, []);

  useEffect(() => {
    if (!apartments && periods) {
      getApartmentsFromCMS();
    }
  }, [periods]);

  return (
    <Suspense fallback={spinner}>
      <Routes>
        <Route
          path={"/:language"}
          element={<MainPageWithTextProvider {...props} />}
        >
          <Route path="" element={<FrontPage {...props} />} />
          <Route path={"book"} element={<ContactSection {...props} />} />

          <Route path={"cuba"} element={<CubaSection {...props} />} />

          {!events
            ? null
            : events.map((event) => {
                const path = "event/" + event.eventId + "/*";
                const pathURL = path.replaceAll(" ", "_");
                return (
                  <Route
                    path={pathURL}
                    key={event.eventId}
                    element={<Event {...props} eventId={event.eventId} />}
                  >
                    <Route
                      path="program"
                      element={
                        <ProgramWidget {...props} eventId={event.eventId} />
                      }
                    />
                    <Route
                      path="locations"
                      element={
                        <LocationsWidget {...props} eventId={event.eventId} />
                      }
                    />
                    <Route
                      path="demands"
                      element={
                        <DemandsWidget {...props} eventId={event.eventId} />
                      }
                    />
                    <Route
                      path="book"
                      element={<ContactSection {...props} />}
                    />
                  </Route>
                );
              })}

          <Route path="book/*" element={<ContactSection {...props} />} />

          <Route path="jubileusz" element={<Offer25Years {...props} />} />

          <Route
            path="jubileusz/rezerwuj"
            element={<ContactUs25Years {...props} />}
          />

          <Route path="about/*" element={<AboutSection {...props} />} />

          <Route path="croatia" element={<CroatiaSection {...props} />} />

          <Route path="croatia/book" element={<PricesCroatia {...props} />}>
            <Route path="contact" element={<ContactSection />} />
          </Route>

          <Route path="croatia/dive_sites" element={<DiveSites />} />

          <Route
            path="croatia/nautilus"
            element={<NautilusCenter {...props} />}
          />

          {!apartments
            ? null
            : apartments.map((apartment) => {
                const path = "croatia/appartments/" + apartment.firebaseId;
                const pathURL = path.replaceAll(" ", "_");

                return (
                  <Route
                    path={pathURL}
                    key={apartment.firebaseId}
                    element={
                      <ApartmentDetails
                        {...props}
                        firebaseId={apartment.firebaseId}
                      />
                    }
                  />
                );
              })}

          <Route
            path="croatia/appartments"
            element={<BookAppartment {...props} />}
          />

          <Route
            path={"trips"}
            element={<TripsSection {...props} events={events} />}
          >
            <Route
              path={"kamczatka"}
              element={<Kamczatka {...props} events={events} />}
            />
            <Route
              path={"bajkal"}
              element={<Bajkal {...props} events={events} />}
            />
            <Route
              path={"orda"}
              element={<Orda {...props} events={events} />}
            />
            <Route
              path={"kuba"}
              element={<Kuba {...props} events={events} />}
            />
            <Route
              path={"meksyk"}
              element={<Meksyk {...props} events={events} />}
            />
            <Route
              path={"barents"}
              element={<Barents {...props} events={events} />}
            />
            <Route
              path={"filipiny"}
              element={<Filipiny {...props} events={events} />}
            />
            <Route
              path={"malediwy"}
              element={<Malediwy {...props} events={events} />}
            />
            <Route
              path={"grenlandia"}
              element={<Grenlandia {...props} events={events} />}
            />
          </Route>

          <Route path="courses" element={<CoursesSection {...props} />} />

          <Route
            path={"courses/beginners/*"}
            element={
              <CoursesDetails
                {...props}
                path="courses/beginners"
                event="Kursy dla początkujących"
                courseCategory="podstawowe"
                slogan="Tu zaczyna się Twoja nurkowa przygoda"
                h1="Kursy nurkowania"
                image={beginners}
              />
            }
          />

          <Route
            path={"courses/advanced/*"}
            element={
              <CoursesDetails
                {...props}
                path="courses/advanced"
                event="Kursy dla zaawansowanych"
                courseCategory="zaawansowane"
                slogan="Rozwijaj swoje umiejętności"
                h1="Kursy nurkowania dla zaawansowanych"
                image={advanced}
              />
            }
          />

          <Route
            path={"courses/specialisation/*"}
            element={
              <CoursesDetails
                {...props}
                path="courses/specialisation"
                event="Kursy specjalizacyjne"
                courseCategory="specjalizacje"
                slogan="Zostań mistrzem"
                h1="Kursy nurkowania specjalizacyjne"
                image={specials}
              />
            }
          />

          <Route
            path={"courses/kids/*"}
            element={
              <CoursesDetails
                {...props}
                path="courses/kids"
                event="Kursy dla dzieci"
                courseCategory="dzieci"
                slogan="Odkryj fascynujący podwodny świat"
                h1="Kursy nurkowania dla dzieci"
                image={kids}
              />
            }
          />

          <Route path="gallery" element={<GallerySection {...props} />}>
            <Route path="croatia" element={<CroatiaGallery />} />
            <Route path="barents" element={<BarentsGallery />} />
            <Route path="kamczatka" element={<KamczatkaGallery />} />
            <Route path="bajkal" element={<BajkalGallery />} />
            <Route path="egipt" element={<EgiptGallery />} />
            <Route path="orda" element={<OrdaGallery />} />
            <Route path="meksyk" element={<MeksykGallery />} />
            <Route path="kuba" element={<KubaGallery />} />
            <Route path="grenlandia" element={<GrenlandiaGallery />} />
          </Route>

          {/*<Route path="blog/jak_wybrac_sprzet_nurkowy" element={<Blog1 />} />*/}

          {/*<Route path="blog/jak_zaczac_nurkowac" element={<Blog2 />} />*/}

          <Route path="blog/jaskinia_ordynska" element={<Blog3 />} />

          {!blog
            ? null
            : blog.map((blogEntry) => {
                const path = "blog/" + blogEntry.firebaseId + "/*";
                const pathURL = path.replaceAll(" ", "_");
                return (
                  <Route
                    path={pathURL}
                    key={blogEntry.firebaseId}
                    element={
                      <BlogEntry {...props} blogId={blogEntry.firebaseId} />
                    }
                  />
                );
              })}

          <Route path="blog" element={<Blog {...props} />} />
        </Route>

        <Route path="/*" element={<PageNotFoundScreen />} />
      </Routes>
    </Suspense>
  );
}
