import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyClyCSxXS9F4fmaOLlh2xwklqPqYJdUVdk",
  authDomain: "nautilus-web-page.firebaseapp.com",
  databaseURL:
    "https://nautilus-web-page-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "nautilus-web-page",
  storageBucket: "nautilus-web-page.appspot.com",
  messagingSenderId: "934084195855",
  appId: "1:934084195855:web:b70ff90f05f0e093b9557a",
  measurementId: "G-4HEJYF5D7K",
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);

export default firebase;
