import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { CssBaseline } from "@material-ui/core";

import store from "utils/store.js";
import "fontsource-roboto";
import "assets/css/hidden.css";
import "assets/css/diveSideCard.css";
import "assets/scss/material-kit-react.scss?v=1.9.0";
import Routing from "./pages/MainPage/Routing";

const App = () => {
  return (
    <Provider store={store}>
      <CssBaseline>
        <BrowserRouter>
          <Routing />
        </BrowserRouter>
      </CssBaseline>
    </Provider>
  );
};

export default App;
