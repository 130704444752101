import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import addEventImage from "utils/addEventImage";
import { animateScroll as scroll } from "react-scroll";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import imagesStyles from "assets/jss/material-kit-react/imagesStyles.js";
import setEvent from "utils/setEvent.js";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import More from "@material-ui/icons/MoreHoriz";
import { useSoftRiseShadowStyles } from "@mui-treasury/styles/shadow/softRise";
import { Info, InfoSubtitle, InfoTitle } from "@mui-treasury/components/info";
import { useGalaxyInfoStyles } from "@mui-treasury/styles/info/galaxy";
import { useCoverCardMediaStyles } from "@mui-treasury/styles/cardMedia/cover";

// import LazyLoad from "react-lazyload";
import { useCMS } from "utils/useCMS";

const cardStyles = {
  card: {
    borderRadius: "5px",
    position: "relative",
    minWidth: 200,
    minHeight: 200,
    marginBottom: 25,
    boxShadow:
      "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    "&:after": {
      content: '""',
      display: "block",
      position: "absolute",
      width: "100%",
      height: "64%",
      bottom: 0,
      zIndex: 1,
      background: "linear-gradient(to top, #000, rgba(0,0,0,0))",
    },
  },
  content: {
    position: "absolute",
    zIndex: 2,
    bottom: 0,
    width: "100%",
  },
};

const styles = {
  ...imagesStyles,
  ...cardStyles,
};

const useStyles = makeStyles(styles);

const CalendariumItem = (props) => {
  const mediaStyles = useCoverCardMediaStyles({ bgPosition: "top" });
  const classes = useStyles();
  const shadowStyles = useSoftRiseShadowStyles();
  const navigate = useNavigate();
  const { language } = useParams();
  const { getImageUrl } = useCMS();
  let displayedItem = <CircularProgress />;

  const image = useSelector(
    (state) => state.eventImages.eventImages[props.eventId]
  );

  // eslint-disable-next-line
  useEffect(() => {
    if (!image && props.event.header_image) {
      getImageUrl(props.event.header_image)
        .then((url) => {
          addEventImage(props.eventId, url);
        })
        .catch((error) => console.error(error));
    }
  }, [image, props.event.header_image, props.eventId]);

  function bookClicked(e) {
    setEvent(props.eventId);
    e.preventDefault();
    scroll.scrollTo(330);
    const path = `/${language}/event/${props.eventId}`;
    const pathURL = path.replaceAll(" ", "_");
    navigate(pathURL);
  }

  if (image) {
    displayedItem = <CardMedia classes={mediaStyles} image={image} />;
  }

  return (
    <Card
      className={cx(classes.card, shadowStyles.root)}
      onClick={(e) => bookClicked(e)}
    >
      {displayedItem}
      <Box py={3} px={2} className={classes.content}>
        <Info useStyles={useGalaxyInfoStyles}>
          <InfoSubtitle>
            {props.event.dateFrom} - {props.event.dateTo}
          </InfoSubtitle>
          <InfoTitle>{props.event.title}</InfoTitle>
        </Info>
        <Fab
          size="small"
          variant="circular"
          style={{
            backgroundColor: "#00acc1",
            marginTop: "10px",
            opacity: "0.7",
          }}
        >
          <More className={classes.icons} style={{ color: "white" }} />
        </Fab>
      </Box>
    </Card>
  );
};

CalendariumItem.propTypes = {
  event: PropTypes.object,
  eventId: PropTypes.string,
};

export default CalendariumItem;
