import seoJSON from "assets/seo.json";
import { useSelector } from "react-redux";
import setSeo from "utils/setSeo";

export default function useSeo(url) {
  let seo = useSelector((state) => state.seo.seo);

  let seoUrl = null;
  if (!seo) {
    const seoData = JSON.parse(JSON.stringify(seoJSON));
    setSeo(seoData);
    seoUrl = seoData[url];
  } else {
    seoUrl = seo.name[url];
  }

  return seoUrl;
}
