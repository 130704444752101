import { configureStore } from "@reduxjs/toolkit";
import banner from "assets/img/banner/chorwacja.jpg";

const _ = require("lodash");

const initialState = {
  apartments: null,
  periods: null,
  event: {
    name: "",
  },
  banner: {
    isVideo: false,
    image: banner,
    slogan: "Łączy nas podwodna pasja.",
    slogan2: "Poczuj moc nurkowania.",
  },
  blog: null,
  events: null,
  eventImages: {},
  downloadDetailsUrls: {},
  seo: null,
  faces: {},
  news: null,
  h1: null,
};

function EventReducer(state = initialState, action) {
  // Check to see if the reducer cares about this action
  if (action.type === "event/set") {
    // If so, make a copy of `state`
    const newState = {
      ...state,
      event: {
        name: action.payload,
      },
    };

    return newState;
  }
  // otherwise return the existing state unchanged
  return state;
}

function BlogReducer(state = initialState, action) {
  // Check to see if the reducer cares about this action
  if (action.type === "blog/set") {
    // If so, make a copy of `state`

    return {
      ...state,
      blog: action.payload,
    };
  }
  // otherwise return the existing state unchanged
  return state;
}

function ApartmentReducer(state = initialState, action) {
  if (action.type === "apartments/set") {
    return {
      ...state,
      apartments: action.payload,
    };
  }
  return state;
}

function H1Reducer(state = initialState, action) {
  if (action.type === "h1/set") {
    const newState = {
      ...state,
      h1: action.payload,
    };

    return newState;
  }
  return state;
}

function EventImagesReducer(state = initialState, action) {
  if (action.type === "eventImages/add") {
    const newState = {
      ...state,
      eventImages: {
        ...state.eventImages,
      },
    };
    newState.eventImages[action.eventId] = action.payload;
    return newState;
  }
  return state;
}

function downloadDetailsUrlsReducer(state = initialState, action) {
  if (action.type === "downloadDetailsUrls/add") {
    const newState = {
      ...state,
      downloadDetailsUrls: {
        ...state.downloadDetailsUrls,
      },
    };
    newState.downloadDetailsUrls[action.eventId] = action.payload;
    return newState;
  }
  return state;
}

function FacesReducer(state = initialState, action) {
  if (action.type === "faces/add") {
    const newState = {
      ...state,
      faces: { ...state.faces },
    };
    newState.faces[action.name] = action.payload;
    return newState;
  }
  return state;
}

function SeoReducer(state = initialState, action) {
  if (action.type === "seo/set") {
    const newState = {
      ...state,
      seo: {
        name: action.payload,
      },
    };

    return newState;
  }
  return state;
}

function EventsReducer(state = initialState, action) {
  if (action.type === "events/set") {
    return {
      ...state,
      events: action.payload,
    };
  } else if (action.type === "eventDemands/set") {
    const newState = _.cloneDeep(state);
    newState.events[action.payload.eventId].demands = action.payload.demands;
    return newState;
  }
  return state;
}

function BannerReducer(state = initialState, action) {
  if (action.type === "banner/setImage") {
    const newState = {
      ...state,
      banner: {
        ...state.banner,
        image: action.payload,
      },
    };

    return newState;
  } else if (action.type === "banner/setSlogan") {
    const newState = {
      ...state,
      banner: {
        ...state.banner,
        slogan: action.payload,
      },
    };

    return newState;
  } else if (action.type === "banner/setSlogan2") {
    const newState = {
      ...state,
      banner: {
        ...state.banner,
        slogan2: action.payload,
      },
    };

    return newState;
  } else if (action.type === "banner/setVideo") {
    const newState = {
      ...state,
      banner: {
        ...state.banner,
        isVideo: action.payload,
      },
    };

    return newState;
  }
  return state;
}

function NewsReducer(state = initialState, action) {
  if (action.type === "news/set") {
    const newState = {
      ...state,
      news: action.payload,
    };

    return newState;
  }
  return state;
}

function PeriodsReducer(state = initialState, action) {
  if (action.type === "periods/set") {
    const newState = {
      ...state,
      periods: action.payload,
    };

    return newState;
  }
  return state;
}

export default configureStore({
  reducer: {
    apartments: ApartmentReducer,
    periods: PeriodsReducer,
    event: EventReducer,
    blog: BlogReducer,
    banner: BannerReducer,
    events: EventsReducer,
    seo: SeoReducer,
    faces: FacesReducer,
    news: NewsReducer,
    eventImages: EventImagesReducer,
    downloadDetailsUrls: downloadDetailsUrlsReducer,
    h1: H1Reducer,
  },
});
