import React, { useContext } from "react";
import { TextContext } from "context/TextProvider";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.js";
import Typography from "@material-ui/core/Typography";
import Close from "@material-ui/icons/Close";
import styles from "assets/jss/material-kit-react/views/componentsSections/javascriptStyles.js";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props) {
  return <Slide direction="down" {...props} />;
});

Transition.displayName = "Transition";

const RODOModal = (props) => {
  function modalClose() {
    props.setShowRodo(false);
  }

  const classes = useStyles();
  const dictionary = useContext(TextContext);
  let color = "info";

  let buttonText = "zamknij";

  const buildList = (list) =>
    list.map((item) => (
      <li key={item}>
        <Typography varinat="body1">{item}</Typography>
      </li>
    ));

  return (
    <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal,
      }}
      open={props.showRodo}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => modalClose()}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <IconButton
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => modalClose()}
        >
          <Close className={classes.modalClose} />
        </IconButton>

        <h4 className={classes.modalTitle}>{dictionary.rodoModal.title}</h4>
      </DialogTitle>

      <DialogContent
        id="classic-modal-slide-description"
        className={classes.modalBody}
      >
        <Typography variant="h6">{dictionary.rodoModal.section1}</Typography>
        <Typography varinat="body1">
          {dictionary.rodoModal.text1_1}
          <br />
        </Typography>
        <br />
        <Typography variant="h6">{dictionary.rodoModal.section2}</Typography>
        <Typography varinat="body1">
          {dictionary.rodoModal.text2_1}
          <br />
        </Typography>
        <br />
        <Typography variant="h6">{dictionary.rodoModal.section3}</Typography>
        <Typography varinat="body1">{dictionary.rodoModal.text3_1}</Typography>
        <div>
          <ul>{buildList(dictionary.rodoModal.list3_1)}</ul>
        </div>
        <Typography varinat="body1">
          {dictionary.rodoModal.text3_2}
          <br />
        </Typography>
        <br />
        <Typography variant="h6">{dictionary.rodoModal.section4}</Typography>
        <Typography varinat="body1">{dictionary.rodoModal.text4_1}</Typography>
        <div>
          <ul>{buildList(dictionary.rodoModal.list4_1)}</ul>
        </div>
        <Typography varinat="body1">
          {dictionary.rodoModal.text4_2}
          <br />
        </Typography>
        <br />
        <Typography variant="h6">{dictionary.rodoModal.section5}</Typography>
        <Typography varinat="body1">{dictionary.rodoModal.text5_1}</Typography>
        <div>
          <ul>{buildList(dictionary.rodoModal.list5_1)}</ul>
        </div>
        <Typography varinat="body1">
          {dictionary.rodoModal.text5_2}
          <br />
        </Typography>
        <br />
        <Typography variant="h6">{dictionary.rodoModal.section6}</Typography>
        <Typography varinat="body1">
          {dictionary.rodoModal.text6_1}
          <br />
        </Typography>
        <br />
        <Typography variant="h6">{dictionary.rodoModal.section7}</Typography>
        <Typography varinat="body1">
          {dictionary.rodoModal.text7_1}
          <br />
        </Typography>
        <br />
        <Typography variant="h6">{dictionary.rodoModal.section8}</Typography>
        <Typography varinat="body1">
          {dictionary.rodoModal.text8_1}
          <br />
        </Typography>
        <br />
        <Typography variant="h6">{dictionary.rodoModal.section9}</Typography>
        <Typography varinat="body1">
          {dictionary.rodoModal.text9_1}
          <br />
        </Typography>
        <br />
        <Typography variant="h6">{dictionary.rodoModal.section10}</Typography>
        <Typography varinat="body1">
          {dictionary.rodoModal.text10_1}
          <br />
        </Typography>
        <br />
        <Typography variant="h6">{dictionary.rodoModal.section11}</Typography>
        <Typography varinat="body1">
          {dictionary.rodoModal.text11_1}
          <br />
        </Typography>
        <br />
        <Typography variant="h6">{dictionary.rodoModal.section12}</Typography>
        <Typography varinat="body1">
          {dictionary.rodoModal.text12_1}
          <br />
        </Typography>
        <br />
        <Typography variant="h6">{dictionary.rodoModal.section13}</Typography>
        <Typography varinat="body1">{dictionary.rodoModal.text13_1}</Typography>
        <div>
          <ul>{buildList(dictionary.rodoModal.list13_1)}</ul>
        </div>
        <Typography varinat="body1">{dictionary.rodoModal.text13_2}</Typography>
        <div>
          <ul>{buildList(dictionary.rodoModal.list13_2)}</ul>
        </div>
        <Typography varinat="body1">{dictionary.rodoModal.text13_3}</Typography>
      </DialogContent>

      <DialogActions className={classes.modalFooter}>
        <Button onClick={() => modalClose()} color={color} simple>
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

RODOModal.propTypes = {
  setShowRodo: PropTypes.func,
  showRodo: PropTypes.bool,
};

export default RODOModal;
