import TextProvider from "context/TextProvider";
import { React } from "react";
import MainPage from "./MainPage";
import useOnline from "utils/useOnline.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import WifiOffIcon from "@material-ui/icons/WifiOff";
import { useLanguage } from "context/TextProvider";

export default function MainPageWithTextProvider(props) {
  const online = useOnline();
  const dictionary = useLanguage();
  return (
    <TextProvider>
      {online ? null : (
        <center
          style={{
            position: "fixed",
            left: "10%",
            top: "10px",
            width: "80%",
            zIndex: "10000",
          }}
        >
          <SnackbarContent
            message={<span>{dictionary.mainPage.offline}</span>}
            color="warning"
            icon={WifiOffIcon}
            close
          />
        </center>
      )}
      <MainPage {...props} />
    </TextProvider>
  );
}
