import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import imagesStyles from "assets/jss/material-kit-react/imagesStyles.js";
import firebase from "utils/firebase-nautilus.js";
import "@firebase/storage";

import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import { useSoftRiseShadowStyles } from "@mui-treasury/styles/shadow/softRise";

import {
  Info,
  // InfoCaption,
  InfoSubtitle,
  InfoTitle,
} from "@mui-treasury/components/info";
import { useGalaxyInfoStyles } from "@mui-treasury/styles/info/galaxy";
import { useCoverCardMediaStyles } from "@mui-treasury/styles/cardMedia/cover";

import LazyLoad from "react-lazyload";
import { Typography } from "@material-ui/core";

const cardStyles = {
  card: {
    borderRadius: "5px",
    position: "relative",
    minWidth: 200,
    minHeight: 350,
    marginBottom: 25,
    boxShadow:
      "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    "&:after": {
      content: '""',
      display: "block",
      position: "absolute",
      width: "100%",
      height: "64%",
      bottom: 0,
      zIndex: 1,
      background: "linear-gradient(to top, #000, rgba(0,0,0,0))",
    },
  },
  content: {
    position: "absolute",
    zIndex: 2,
    bottom: 0,
    width: "100%",
  },
};

const styles = {
  ...imagesStyles,
  ...cardStyles,
};

const useStyles = makeStyles(styles);

const NewsCard = (props) => {
  const [image, setImage] = useState(null);
  const storage = firebase.storage().ref();

  storage
    .child("news_photos/" + props.image)
    .getDownloadURL()
    .then((url) => {
      setImage(url);
    })
    .catch((error) => {
      console.log("error getDownUrl");
      console.log(error);
    });

  const mediaStyles = useCoverCardMediaStyles({ bgPosition: "top" });
  const classes = useStyles();
  const shadowStyles = useSoftRiseShadowStyles();

  let displayedItem = <CircularProgress />;

  if (image) {
    displayedItem = (
      <LazyLoad once>
        <CardMedia classes={mediaStyles} image={image} />
      </LazyLoad>
    );
  }

  return (
    <Card
      className={cx(classes.card, shadowStyles.root)}
      itemProp="offers"
      itemScope
      itemType="https://schema.org/Event"
    >
      {displayedItem}

      <Box py={3} px={2} className={classes.content}>
        <Info useStyles={useGalaxyInfoStyles}>
          {props.date ? (
            <InfoSubtitle itemProp="startDate">{props.date}</InfoSubtitle>
          ) : null}
          <InfoTitle itemProp="name">{props.title}</InfoTitle>
        </Info>
        <Typography
          variant="caption"
          style={{ color: "white" }}
          itemProp="description"
        >
          {props.body}
        </Typography>
        <br></br>
        {props.link ? (
          <Fab
            size="small"
            variant="extended"
            style={{
              backgroundColor: "#00acc1",
              color: "white",
              marginTop: "10px",
              opacity: "0.7",
            }}
            href={props.link}
          >
            dowiedz się więcej
          </Fab>
        ) : null}
      </Box>
    </Card>
  );
};

NewsCard.propTypes = {
  image: PropTypes.string,
  date: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  link: PropTypes.string,
};

export default NewsCard;
