import React, { useState, useContext, useEffect } from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import FormLabel from "@material-ui/core/FormLabel";
import Logo from "assets/img/logo.jpeg";
import SSI from "assets/img/SSI.jpeg";
import PADI from "assets/img/PADI.png";
import CMAS from "assets/img/CMAS.jpeg";

import { ColumnToRow, Row, Item } from "@mui-treasury/components/flex";
import { NavMenu, NavItem } from "@mui-treasury/components/menu/navigation";
import {
  EmailSubscribe,
  EmailTextInput,
  SubmitButton,
} from "@mui-treasury/components/EmailSubscribe";
import {
  SocialProvider,
  SocialLink,
} from "@mui-treasury/components/socialLink";

import Check from "@material-ui/icons/Check";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

import { useMoonSocialLinkStyles } from "@mui-treasury/styles/socialLink/moon";
import { useBootstrapEmailSubscribeStyles } from "@mui-treasury/styles/emailSubscribe/bootstrap";
import { usePlainNavigationMenuStyles } from "@mui-treasury/styles/navigationMenu/plain";

import RODOModal from "components/Modal/RODOModal.js";
import subscribe from "utils/setEvent";
import { TextContext } from "context/TextProvider";
import { applyHiddenAnimation } from "utils/applyHiddenAnimation";

const useStyles = makeStyles(({ typography, palette, breakpoints }) => ({
  legalLink: {
    ...typography.caption,
    justifyContent: "center",
    color:
      palette.type === "dark"
        ? "rgba(255,255,255,0.57)"
        : palette.text.secondary,
    position: "relative",
    [breakpoints.up("sm")]: {
      "&:not(:first-of-type)": {
        "&:before": {
          content: '"|"',
          display: "block",
          position: "absolute",
          left: 0,
        },
      },
    },
  },
  newsletter: {
    fontSize: typography.caption.fontSize,
  },
  navMenu: {
    flexWrap: "wrap",
  },
}));

const Footer = () => {
  const dictionary = useContext(TextContext);
  const classes = useStyles();
  const [showRodo, setShowRodo] = useState(false);
  const [showSuccessInfo, setShowSuccesInfo] = useState(false);
  const [showErrorInfo, setShowErrorInfo] = useState(false);

  useEffect(() => {
    const unsubscribeFunction = applyHiddenAnimation();
    return unsubscribeFunction;
  }, []);

  let infoBar = null;

  if (showSuccessInfo) {
    infoBar = (
      <SnackbarContent
        message={<span>{dictionary.footer.text1}</span>}
        close
        color="success"
        icon={Check}
      />
    );
  }

  if (showErrorInfo) {
    infoBar = (
      <SnackbarContent
        message={
          <span>
            <b>ERROR:</b> {dictionary.footer.text2}
          </span>
        }
        close
        color="danger"
        icon="info_outline"
      />
    );
  }

  return (
    <Box bgcolor={"background.paper"} width={"100%"}>
      {infoBar}

      <RODOModal showRodo={showRodo} setShowRodo={setShowRodo} />

      <Container>
        <Box
          pt={8}
          pb={2}
          itemScope
          itemType="https://schema.org/LocalBusiness"
        >
          <Row wrap>
            <Item grow={2}>
              <Row alignItems={"center"} className="delayed">
                <Item
                  color={"#007bff"}
                  fontSize={64}
                  lineHeight={0}
                  className="hidden"
                >
                  <img
                    itemProp="logo"
                    src={Logo}
                    alt="logo"
                    width="110px"
                    style={{ marginRight: "20px" }}
                  />
                </Item>
                <Item className="hidden">
                  <Typography
                    variant={"h6"}
                    color={"textSecondary"}
                    itemProp="name"
                  >
                    {dictionary.footer.text3}
                  </Typography>
                  <Typography variant="body2" color={"textSecondary"}>
                    <span itemProp="address">
                      ul.Kapelanka 1a, 30-347 Kraków
                    </span>
                    <br />
                    <br />
                    <a
                      itemProp="telephone"
                      href="tel:+385-914-000-229"
                      style={{ color: "grey" }}
                    >
                      {dictionary.footer.text5} +385 914 000 299
                    </a>
                    <br />
                    <a
                      itemProp="telephone"
                      href="tel:+48-508-277-778"
                      style={{ color: "grey" }}
                    >
                      Agnieszka +48 508 277 778
                    </a>
                    <br />
                    <a
                      itemProp="telephone"
                      href="tel:+48-501-327-878"
                      style={{ color: "grey" }}
                    >
                      Jakub +48 501 327 878
                    </a>
                    <br />
                    <a
                      itemProp="telephone"
                      href="tel:+48-501-770-993"
                      style={{ color: "grey" }}
                    >
                      Szymon +48 501 770 993
                    </a>
                    <br />
                    <br />
                    <span>
                      <b>Nautilus</b> jest organizatorem turystyki.
                    </span>
                    <br />
                    <span>
                      Wpis do rejestru organizatorów turystyki nr Z/27/2012
                    </span>
                    <br />
                    <span>Gwarancja ubezpieczeniowa: TU Europa</span>
                  </Typography>
                </Item>
              </Row>

              <Typography
                variant="body2"
                color={"textSecondary"}
                className="hidden"
              >
                <br />
                {dictionary.footer.text6}
              </Typography>

              <NavMenu className={`${classes.navMenu} hidden`}>
                <NavItem
                  href="http://cmas.pl/"
                  rel="nofollow"
                  className="hidden"
                >
                  <img src={CMAS} alt="cmas" height="35px" />
                </NavItem>
                <NavItem
                  href="https://www.padi.com/"
                  rel="nofollow"
                  className="hidden"
                >
                  <img src={PADI} alt="padi" height="40px" />
                </NavItem>
                <NavItem
                  href="https://www.divessi.com/divecenter/739074"
                  rel="nofollow"
                  className="hidden"
                >
                  <img src={SSI} alt="ssi" height="30px" />
                </NavItem>
              </NavMenu>
            </Item>

            <Item grow maxWidth={500} mx={"auto"} className="hidden">
              <Box
                textAlign={"center"}
                mt={{ xs: 2, md: 0 }}
                my={2}
                className="delayed"
              >
                <SocialProvider useStyles={useMoonSocialLinkStyles}>
                  <SocialLink
                    brand={"FacebookCircle"}
                    href={"https://www.facebook.com/NautilusKrakow/"}
                    rel="nofollow"
                    className="hidden"
                  />
                  <SocialLink
                    brand={"Instagram"}
                    href={"https://www.instagram.com/diving.nautilus/"}
                    rel="nofollow"
                    className="hidden"
                  />
                  <SocialLink
                    itemProp="email"
                    content="office@nautilus.com.pl"
                    brand={"Envelope"}
                    href={"mailto:office@nautilus.com.pl"}
                    rel="nofollow"
                    className="hidden"
                  />
                  <SocialLink
                    brand={"Whatsapp"}
                    href={"https://wa.me/48501770995"}
                    rel="nofollow"
                    className="hidden"
                  />
                </SocialProvider>
              </Box>
              <FormLabel
                htmlFor={"newsletter"}
                className={`${classes.newsletter} hidden`}
              >
                {dictionary.footer.text7}
              </FormLabel>
              <EmailSubscribe
                onSubmit={(email) =>
                  subscribe(email, setShowSuccesInfo, setShowErrorInfo)
                }
                useStyles={useBootstrapEmailSubscribeStyles}
                inputClearedAfterSubmit
                className="hidden"
              >
                <EmailTextInput
                  id={"newsletter"}
                  placeholder={dictionary.footer.text8}
                  className="hidden"
                />
                <SubmitButton
                  style={{ backgroundColor: "#45aec3", border: "0px" }}
                >
                  {dictionary.footer.text9}
                </SubmitButton>
              </EmailSubscribe>
            </Item>
          </Row>
        </Box>
        <Divider />
        <Box pt={2} pb={10}>
          <ColumnToRow
            at={"md"}
            columnStyle={{ alignItems: "center" }}
            rowStyle={{ alignItems: "unset" }}
          >
            <Item grow ml={-2} shrink={0}>
              <NavMenu useStyles={usePlainNavigationMenuStyles}>
                <ColumnToRow at={"sm"}>
                  <NavItem
                    className={cx(classes.legalLink)}
                    onClick={() => setShowRodo(true)}
                  >
                    {dictionary.footer.text10}
                  </NavItem>
                </ColumnToRow>
              </NavMenu>
            </Item>
            <Item>
              <Box py={1} textAlign={{ xs: "center", md: "right" }}>
                <Typography
                  component={"p"}
                  variant={"caption"}
                  color={"textSecondary"}
                  className="hidden"
                >
                  Designed by{" "}
                  <a
                    href="mailto:lukaszk76@gmail.com"
                    style={{ color: "grey" }}
                    rel="nofollow"
                  >
                    Łukasz Kaczmarek
                  </a>{" "}
                  © 2021 All right reserved
                </Typography>
              </Box>
            </Item>
          </ColumnToRow>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
