import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useCMS } from "../../utils/useCMS";
import setVideo from "../../utils/setVideo";
import setBanner from "../../utils/setBanner";
import croatiaImage from "../../assets/img/banner/chorwacja.jpg";
import setSlogan from "../../utils/setSlogan";
import { Text } from "../Text/Text";
import Box from "@material-ui/core/Box";
import { Image } from "../Image/Image";
import { Video } from "../Video/Video";
import Helmet from "react-helmet";
import { Fab, Typography } from "@material-ui/core";
import ApartmentCardSmall from "../ApartmentCard/ApartmentCardSmall";
import { useParams } from "react-router-dom";
import { PriceList } from "./PriceList";
import CloseIcon from "@material-ui/icons/Close";
import ContactSection from "../../pages/MainPage/Sections/Contact/ContactSection";
import setEvent from "../../utils/setEvent";
import { animateScroll as scroll, Element, scroller } from "react-scroll";
import Button from "components/CustomButtons/Button.js";

import "assets/css/hidden.css";

const useStyles = makeStyles(styles);

export const ApartmentDetails = memo((props) => {
  const classes = useStyles();
  const { language } = useParams();
  const apartments = useSelector((state) => state.apartments.apartments);
  const periods = useSelector((state) => state.periods.periods);
  const [headerPhoto, setHeaderPhoto] = useState(null);
  const [isBooking, setIsBooking] = useState(false);
  const { getImageUrl } = useCMS();

  const apartment = useMemo(
    () => apartments.find((entry) => entry.firebaseId === props.firebaseId),
    [apartments, props.firebaseId]
  );

  const bookApartment = () => {
    setIsBooking(true);
    setEvent(apartment.name);
    scroller.scrollTo(
      "book apartment",
      {
        spy: true,
        smooth: true,
        offset: -100,
        duration: 500,
      },
      []
    );
  };
  const endBooking = () => {
    setIsBooking(false);
    scroll.scrollToTop();
  };

  const initialPriceList = {};

  periods.forEach((period) => {
    initialPriceList[period.period] = [];
  });

  const [priceList, setPriceList] = useState(initialPriceList);

  useEffect(() => {
    if (apartment.headerPhoto) {
      getImageUrl(apartment.headerPhoto).then((imageUrl) =>
        setHeaderPhoto(imageUrl)
      );
    }
  }, [apartment, getImageUrl]);

  useEffect(() => {
    setVideo(false);
    setBanner(headerPhoto || croatiaImage);
    setSlogan(apartment.name, apartment.shortDescription);
  }, [headerPhoto, apartment]);

  const buildPriceList = useCallback(
    (period) => {
      const priceListForPeriod = [];

      apartment.prices.forEach((price) => {
        if (price.period === period) {
          priceListForPeriod.push(price);
        }
      });
      return priceListForPeriod.sort((a, b) => a.capacity - b.capacity);
    },
    [apartment]
  );

  useEffect(() => {
    Object.keys(initialPriceList).forEach((period) => {
      setPriceList((prevState) => ({
        ...prevState,
        [period]: buildPriceList(period),
      }));
    });
  }, [apartment, buildPriceList]);

  const handleImageClick = useCallback((image) => {
    const imageElement = document.getElementById(image);
    const imageContainerElement = imageElement.parentElement;
    imageContainerElement.classList.add("hidden");
    imageElement.classList.add("hidden");
    const imageSrc = imageElement.src;
    const imageAlt = imageElement.alt;

    const imageContainer = document.createElement("img");
    imageContainer.id = "large-image";
    imageContainer.src = imageSrc;
    imageContainer.alt = imageAlt;

    setTimeout(() => {
      imageContainer.classList.add("active");
    }, 0);

    imageContainer.addEventListener("click", () => {
      imageContainer.classList.remove("active");
      imageElement.classList.remove("hidden");
      imageContainerElement.classList.remove("hidden");
      setTimeout(() => {
        imageContainer.remove();
      }, 500);
    });
    document.body.appendChild(imageContainer);
  }, []);

  let content = null;
  if (apartment.content) {
    content = apartment.content.map((item) => {
      if (item.type === "text") {
        return (
          <Text
            markdown={item.value}
            key={item.value}
            className={classes.description}
          />
        );
      } else if (item.type === "images") {
        return (
          <Box
            sx={{
              display: "grid",
              gap: "1rem",
              gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
              width: "100%",
            }}
            key={item.value[0]}
          >
            {item.value.map((image) => {
              return (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignContent: "flex-start",
                  }}
                  key={image}
                >
                  <Image
                    image={image}
                    id={image}
                    alt="post image"
                    imageClassName="gallery-image post-image"
                    className="gallery-image-container"
                    onClick={() => handleImageClick(image)}
                  />
                </Box>
              );
            })}
          </Box>
        );
      } else if (item.type === "video") {
        return (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignContent: "flex-start",
            }}
            key={item.value}
          >
            {item.value.map((video) => (
              <Video src={video} key={video} />
            ))}
          </Box>
        );
      }
    });
  }

  const filtrator = useCallback(
    (apartment) => {
      return apartment.firebaseId !== props.firebaseId;
    },
    [props.firebaseId]
  );

  let bookElement = null;
  if (isBooking) {
    bookElement = (
      <div>
        <Fab
          style={{
            color: "white",
            backgroundColor: "#45aec3",
            opacity: "0.7",
            marginRight: "10px",
            border: "2px",
          }}
          size="small"
          variant="circular"
          onClick={endBooking}
        >
          <CloseIcon style={{ color: "white" }} />
        </Fab>

        <ContactSection {...props} />
      </div>
    );
  }

  if (!apartment || !apartment.content) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{`Nautilus - ${apartment.name}`}</title>
        {/*<meta name="description" content={apartment.seoDescription} />*/}
        <meta name="robots" content="" />
      </Helmet>
      <Box className="responsive-grid">
        <Box
          className={classes.section}
          style={{
            width: "100%",
            maxWidth: "700px",
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            padding: "40px 0",
          }}
        >
          {content}
          <PriceList priceList={priceList} />
          {!isBooking && (
            <Button color="info" fullWidth={true} onClick={bookApartment}>
              Zarezerwuj
            </Button>
          )}
          <Element name="book apartment">{bookElement}</Element>
        </Box>

        <Box className={"column"}>
          <Typography variant="h4" className={classes.description}>
            Sprawdź również:
          </Typography>
          {apartments.length &&
            apartments
              .filter(filtrator)
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((apartment) => (
                <ApartmentCardSmall
                  cmsImage={apartment.headerPhoto}
                  key={apartment.firebaseId}
                  title={apartment.name}
                  description={apartment.shortDescription}
                  path={`/${language}/croatia/appartments/${apartment.firebaseId}`}
                />
              ))}
        </Box>
      </Box>
    </>
  );
});

ApartmentDetails.displayName = "ApartmentDetails";

ApartmentDetails.propTypes = {
  firebaseId: PropTypes.string,
};
