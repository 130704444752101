import React, { useCallback } from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import { useSoftRiseShadowStyles } from "@mui-treasury/styles/shadow/softRise";
import { useSlopeCardMediaStyles } from "@mui-treasury/styles/cardMedia/slope";
import { useN01TextInfoContentStyles } from "@mui-treasury/styles/textInfoContent/n01";
import TextInfoContent from "@mui-treasury/components/content/textInfo";
import { Typography } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import { animateScroll as scroll } from "react-scroll";
import { useCMS } from "../../utils/useCMS";

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 304,
    margin: "auto",
  },
  content: {
    padding: 24,
  },
  avatar: {
    width: 50,
    height: 50,
    border: "2px solid #fff",
    margin: "-48px 32px 0 auto",
    "& > img": {
      margin: 0,
    },
  },
}));

const BlogCard = (props) => {
  const cardStyles = useStyles();
  const mediaStyles = useSlopeCardMediaStyles();
  const shadowStyles = useSoftRiseShadowStyles();
  const textCardContentStyles = useN01TextInfoContentStyles();
  const navigate = useNavigate();
  const { getImageUrl } = useCMS();

  function readClicked(e) {
    e.preventDefault();
    scroll.scrollTo(330);
    navigate(props.path);
  }

  const [headerPhoto, setHeaderPhoto] = React.useState(null);

  React.useEffect(() => {
    if (props.image) {
      setHeaderPhoto(props.image);
    } else if (props.cmsImage) {
      getImageUrl(props.cmsImage).then((imageUrl) => setHeaderPhoto(imageUrl));
    }
  }, [props.image, props.cmsImage, getImageUrl]);

  const cutToWords = useCallback((text, numberOfWords) => {
    if (!text) return "";
    return text.split(" ").slice(0, numberOfWords).join(" ").concat("...");
  }, []);

  return (
    <Card
      className={cx(cardStyles.root, shadowStyles.root)}
      onClick={(e) => readClicked(e)}
      style={{ marginBottom: "20px" }}
    >
      {headerPhoto && <CardMedia classes={mediaStyles} image={headerPhoto} />}

      {props.avatar && (
        <Avatar className={cardStyles.avatar} src={props.avatar} />
      )}

      <CardContent className={cardStyles.content}>
        <Typography variant="caption">
          {props.author} / {props.date}
        </Typography>
        <TextInfoContent
          classes={textCardContentStyles}
          heading={props.title}
          body={cutToWords(props.description, 30)}
        />
      </CardContent>

      <Box px={2} pb={2} mt={-1}>
        {/* <Fab variant="extended" style={{backgroundColor:"#45aec3", color:"white"}}>czytaj</Fab> */}
        <Button color="info" fullWidth={true}>
          czytaj...
        </Button>
      </Box>
    </Card>
  );
};

BlogCard.propTypes = {
  path: PropTypes.string,
  author: PropTypes.string,
  image: PropTypes.string,
  avatar: PropTypes.string,
  date: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  cmsImage: PropTypes.string,
};

export default BlogCard;
