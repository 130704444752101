import React, { useContext, useState, useEffect, createContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import pl_dictionary from "./languages/pl.json";
import PropTypes from "prop-types";

export const TextContext = createContext({
  ...pl_dictionary.pl,
  kalendarium: pl_dictionary.kalendarium,
});

const supportedLanguages = ["pl"];

export default function TextProvider({ children }) {
  const [dictionary, setDictionary] = useState(pl_dictionary.pl);
  const [kalendarium, setKalendarium] = useState(pl_dictionary.kalendarium);

  const navigate = useNavigate();
  let { language } = useParams();
  useEffect(() => {
    if (supportedLanguages.includes(language)) {
      const url = `https://nautilus-web-page-default-rtdb.europe-west1.firebasedatabase.app/${language}.json`;
      const kalendariumUrl = `https://nautilus-web-page-default-rtdb.europe-west1.firebasedatabase.app/kalendarium.json`;
      fetch(url)
        .then((response) => response.json())
        .then((data) => setDictionary(data));
      fetch(kalendariumUrl)
        .then((response) => response.json())
        .then((data) => {
          setKalendarium(data);
        });
    } else {
      console.error(
        `Unsupported languge "${language}" requested in the URL. Redirecting to a default language version`
      );
      navigate("/pl");
    }
  }, [language]);
  return (
    <TextContext.Provider
      value={{
        ...dictionary,
        kalendarium: kalendarium,
      }}
    >
      {children}
    </TextContext.Provider>
  );
}
TextProvider.propTypes = {
  children: PropTypes.node,
};

export function useLanguage() {
  const context = useContext(TextContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}
