import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "context/TextProvider";

import ReCAPTCHA from "react-google-recaptcha";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core componentsxs
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";

import Modal from "components/Modal/Modal.js";
import RODOModal from "components/Modal/RODOModal.js";
import sendMail from "utils/sendMail.js";

import Mail from "@material-ui/icons/Mail";
import Check from "@material-ui/icons/Check";

import pStyles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import bStyles from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";
import setVideo from "utils/setVideo";
import setBanner from "utils/setBanner";
import setSlogan from "utils/setSlogan";
import { animateScroll as scroll } from "react-scroll";
import { applyHiddenAnimation } from "utils/applyHiddenAnimation";
import banner from "assets/img/banner/chorwacja.jpg";
import Box from "@material-ui/core/Box";
import logo from "assets/img/banner/jubileusz.png";

import "assets/css/jubileusz.css";
const styles = {
  ...pStyles,
  ...bStyles,
};

let sendCopy = false;

const useStyles = makeStyles(styles);

const ContactUs25Years = () => {
  const dictionary = useLanguage();
  const classes = useStyles();
  const navigate = useNavigate();

  const [name, setName] = useState(null);
  const [mail, setMail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [message, setMessage] = useState(null);

  const [modalTitle, setModalTitle] = useState(null);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalMode, setModalMode] = useState("info");
  const [showModal, setShowModal] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showRodo, setShowRodo] = useState(false);
  const [reCAPTCHAverified, setReCAPTCHAverified] = useState(false);

  const modalButtonText = "zamknij";

  function nameChanged(event) {
    setName(event.target.value);
  }

  function mailChanged(event) {
    setMail(event.target.value);
  }

  function phoneChanged(event) {
    setPhone(event.target.value);
  }

  function messageChanged(event) {
    setMessage(event.target.value);
  }

  function sendClicked(e) {
    e.preventDefault();
    sendMail(
      setShowSpinner,
      setShowModal,
      setModalMode,
      setModalTitle,
      setModalMessage,
      sendCopy,
      {
        message: message,
        name: name,
        mail: mail,
        phone: phone,
        event_id: "oferta_jubileuszowa",
      }
    );
  }

  function reCAPTCHAhandler(value) {
    if (value) {
      setReCAPTCHAverified(true);
    } else {
      setReCAPTCHAverified(false);
    }
  }

  function handleToggle() {
    sendCopy = !sendCopy;
  }

  function rodoClicked() {
    setShowRodo(true);
  }

  function modalClosed() {
    navigate("/pl");
  }

  useEffect(() => {
    setVideo(false);
    setBanner(banner);
    setSlogan("Oferta Jubileuszowa", "25% rabatu na 25-lecie Nautilusa");
    scroll.scrollToTop();
    return applyHiddenAnimation();
  }, []);

  let displayedItem = (
    <Zoom in>
      <Box
        cs={12}
        sm={12}
        md={8}
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: "2rem",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          marginTop: "2rem",
        }}
      >
        <div className="jubileusz-header">
          <img
            src={logo}
            alt="jubileusz 25 lat Nautilusa"
            width={80}
            height={80}
            style={{ marginRight: "1rem" }}
          />
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              alignItems: "flex-start",
              justifyItems: "center",
              height: "100%",
            }}
          >
            <Typography variant="h4" className={classes.description}>
              Interesujesz się Ofertą Jubileuszową
            </Typography>
            <Typography variant="body1" className={classes.description}>
              {dictionary.contactUs.text3}
            </Typography>
          </Box>
        </div>
        <form>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText={dictionary.contactUs.text4}
                id="name"
                formControlProps={{
                  fullWidth: true,
                }}
                onChange={nameChanged}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText={dictionary.contactUs.text5}
                id="email"
                formControlProps={{
                  fullWidth: true,
                }}
                onChange={mailChanged}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText={dictionary.contactUs.text6}
                id="phone"
                formControlProps={{
                  fullWidth: true,
                }}
                onChange={phoneChanged}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={12} style={{ marginBottom: "1rem" }}>
              <CustomInput
                labelText={dictionary.contactUs.text7}
                id="message"
                formControlProps={{
                  fullWidth: true,
                  className: classes.textArea,
                }}
                inputProps={{
                  multiline: true,
                  rows: 5,
                }}
                onChange={messageChanged}
              />
            </GridItem>

            <GridItem
              xs={12}
              sm={12}
              md={6}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: "1rem",
              }}
            >
              <Typography variant="caption" className={classes.description}>
                {dictionary.contactUs.text8}
                <span onClick={rodoClicked}>
                  <b>{dictionary.contactUs.text9}</b>
                </span>
                <br />
              </Typography>

              <ReCAPTCHA
                sitekey="6LeHR8MaAAAAAJhQtiEfrwLaBYyrkjKk7ZjsGY4d"
                onChange={reCAPTCHAhandler}
                hl="pl"
                size="normal"
              />
            </GridItem>

            <GridItem
              xs={12}
              sm={12}
              md={6}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: "1rem",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    tabIndex={-1}
                    onClick={handleToggle}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                classes={{ label: classes.label, root: classes.labelRoot }}
                label={dictionary.contactUs.text10}
              />

              <Button
                disabled={!reCAPTCHAverified}
                color="info"
                onClick={(e) => sendClicked(e)}
                style={{ width: "100%" }}
              >
                <Mail className={classes.icons} />
                {dictionary.contactUs.text11}
              </Button>
            </GridItem>
          </GridContainer>
        </form>
      </Box>
    </Zoom>
  );

  if (showSpinner) {
    displayedItem = <CircularProgress />;
  }

  return (
    <div className={classes.section}>
      <RODOModal showRodo={showRodo} setShowRodo={setShowRodo} />

      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        mode={modalMode}
        title={modalTitle}
        message={modalMessage}
        buttonText={modalButtonText}
        modalClosed={modalClosed}
      />

      {displayedItem}
    </div>
  );
};

export default ContactUs25Years;
