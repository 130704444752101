import React, { useContext } from "react";
import ScrollButton from "./ScrollButton.js";
import { TextContext } from "context/TextProvider.js";

const ScrollPanelMain = () => {
  const dictionary = useContext(TextContext);
  return (
    <div>
      <div
        style={{
          position: "fixed",
          bottom: "90px",
          left: "20px",
          zIndex: "100",
        }}
      >
        <ScrollButton target={dictionary.frontPage.newsButton} offset="0" />
      </div>
      <div
        style={{
          position: "fixed",
          bottom: "50px",
          left: "20px",
          zIndex: "100",
        }}
      >
        <ScrollButton target={dictionary.frontPage.offerButton} offset="0" />
      </div>
      <div
        style={{
          position: "fixed",
          bottom: "10px",
          left: "20px",
          zIndex: "100",
        }}
      >
        <ScrollButton target={dictionary.frontPage.eventsButton} offset="0" />
      </div>
    </div>
  );
};

export default ScrollPanelMain;
