import React from "react";
import { Fab } from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import MailIcon from "@material-ui/icons/Mail";
import PhoneIcon from "@material-ui/icons/Phone";
import { animateScroll as scroll } from "react-scroll";
import { useSoftRiseShadowStyles } from "@mui-treasury/styles/shadow/softRise";
import cx from "clsx";

const ButtonUp = () => {
  const shadowStyles = useSoftRiseShadowStyles();
  return (
    <div
      style={{
        position: "fixed",
        bottom: "10px",
        right: "20px",
        zIndex: "100",
      }}
    >
      <Fab
        style={{
          backgroundColor: "#45aec3",
          opacity: "0.7",
          marginRight: "10px",
        }}
        size="small"
        variant="extended"
        onClick={scroll.scrollToBottom}
        className={cx(shadowStyles.root)}
      >
        <PhoneIcon style={{ color: "white", marginRight: "5px" }} />
        <MailIcon style={{ color: "white" }} />
      </Fab>

      <Fab
        style={{
          backgroundColor: "#45aec3",
          opacity: "0.7",
        }}
        size="small"
        variant="extended"
        onClick={scroll.scrollToTop}
        className={cx(shadowStyles.root)}
      >
        <ExpandLessIcon style={{ color: "white" }} />
      </Fab>
    </div>
  );
};

export default ButtonUp;
