import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
// react components for routing our app without refresh
import { Link, useNavigate } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";
import { Home } from "@material-ui/icons";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import { TextContext } from "context/TextProvider";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const { language } = useParams();
  const dictionary = useContext(TextContext);
  const classes = useStyles();
  const navigate = useNavigate();

  function scrollUp() {
    props.toggleMenuDrawer();
    scroll.scrollTo(0);
  }

  function homeCLicked(e) {
    e.preventDefault();
    scroll.scrollToTop();
    props.toggleMenuDrawer();
    navigate(`/${language}`);
  }

  function blogCLicked(e) {
    e.preventDefault();
    scrollUp();
    props.toggleMenuDrawer();
    navigate(`/${language}/blog`);
  }

  function cubaCLicked(e) {
    e.preventDefault();
    scrollUp();
    props.toggleMenuDrawer();
    navigate(`/${language}/cuba`);
  }

  function aboutCLicked(e) {
    e.preventDefault();
    scrollUp();
    props.toggleMenuDrawer();
    navigate(`/${language}/about`);
  }

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Button
          className={classes.navLink}
          onClick={(e) => homeCLicked(e)}
          color="transparent"
        >
          <Home />
        </Button>
      </ListItem>

      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          hoverColor="blue"
          buttonText={dictionary.menu.croatia.main}
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          dropdownList={[
            <Link
              to={`/${language}/croatia/dive_sites`}
              className={classes.dropdownLink}
              onClick={scrollUp}
              key="1"
            >
              {dictionary.menu.croatia.sub1}
            </Link>,

            <Link
              to={`/${language}/croatia/nautilus`}
              className={classes.dropdownLink}
              onClick={scrollUp}
              key="2"
            >
              {dictionary.menu.croatia.sub2}
            </Link>,

            <Link
              to={`/${language}/croatia/appartments`}
              className={classes.dropdownLink}
              onClick={scrollUp}
              key="3"
            >
              {dictionary.menu.croatia.sub3}
            </Link>,

            <Link
              to={`/${language}/croatia/book`}
              className={classes.dropdownLink}
              onClick={scrollUp}
              key="4"
            >
              {dictionary.menu.croatia.sub4}
            </Link>,
          ]}
        />
      </ListItem>

      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          hoverColor="blue"
          buttonText={dictionary.menu.courses.main}
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          dropdownList={[
            <Link
              to={`/${language}/courses/beginners`}
              className={classes.dropdownLink}
              onClick={scrollUp}
              key="1"
            >
              {dictionary.menu.courses.sub1}
            </Link>,

            <Link
              to={`/${language}/courses/advanced`}
              className={classes.dropdownLink}
              onClick={scrollUp}
              key="2"
            >
              {dictionary.menu.courses.sub2}
            </Link>,

            <Link
              to={`/${language}/courses/specialisation`}
              className={classes.dropdownLink}
              onClick={scrollUp}
              key="3"
            >
              {dictionary.menu.courses.sub3}
            </Link>,

            <Link
              to={`/${language}/courses/kids`}
              className={classes.dropdownLink}
              onClick={scrollUp}
              key="4"
            >
              {dictionary.menu.courses.sub4}
            </Link>,
          ]}
        />
      </ListItem>

      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText={dictionary.menu.events.main}
          hoverColor="blue"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          dropdownList={[
            <Link
              to={`/${language}/trips/kamczatka`}
              className={classes.dropdownLink}
              onClick={scrollUp}
              key="1"
            >
              {dictionary.menu.events.sub1}
            </Link>,

            <Link
              to={`/${language}/trips/bajkal`}
              className={classes.dropdownLink}
              onClick={scrollUp}
              key="2"
            >
              {dictionary.menu.events.sub2}
            </Link>,

            <Link
              to={`/${language}/trips/orda`}
              className={classes.dropdownLink}
              onClick={scrollUp}
              key="3"
            >
              {dictionary.menu.events.sub3}
            </Link>,

            <Link
              to={`/${language}/trips/kuba`}
              className={classes.dropdownLink}
              onClick={scrollUp}
              key="4"
            >
              {dictionary.menu.events.sub4}
            </Link>,

            <Link
              to={`/${language}/trips/meksyk`}
              className={classes.dropdownLink}
              onClick={scrollUp}
              key="5"
            >
              {dictionary.menu.events.sub5}
            </Link>,

            <Link
              to={`/${language}/trips/barents`}
              className={classes.dropdownLink}
              onClick={scrollUp}
              key="6"
            >
              {dictionary.menu.events.sub6}
            </Link>,
            <Link
              to={`/${language}/trips/filipiny`}
              className={classes.dropdownLink}
              onClick={scrollUp}
              key="7"
            >
              {dictionary.menu.events.sub7}
            </Link>,
            <Link
              to={`/${language}/trips/malediwy`}
              className={classes.dropdownLink}
              onClick={scrollUp}
              key="8"
            >
              {dictionary.menu.events.sub8}
            </Link>,
            <Link
              to={`/${language}/trips/grenlandia`}
              className={classes.dropdownLink}
              onClick={scrollUp}
              key="9"
            >
              {dictionary.menu.events.sub9}
            </Link>,
          ]}
        />
      </ListItem>

      <ListItem className={classes.listItem}>
        <Button
          className={classes.navLink}
          onClick={(e) => cubaCLicked(e)}
          color="transparent"
          href={`/${language}/cuba`}
        >
          wizy na Kubę
        </Button>
      </ListItem>

      <ListItem className={classes.listItem}>
        <Button
          className={classes.navLink}
          onClick={(e) => blogCLicked(e)}
          color="transparent"
          href={`/${language}/blog`}
        >
          {dictionary.menu.blog.main}
        </Button>
      </ListItem>

      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText={dictionary.menu.gallery.main}
          hoverColor="blue"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          dropdownList={[
            <Link
              to={`/${language}/gallery/croatia`}
              className={classes.dropdownLink}
              onClick={scrollUp}
              key="1"
            >
              {dictionary.menu.gallery.sub1}
            </Link>,

            <Link
              to={`/${language}/gallery/barents`}
              className={classes.dropdownLink}
              onClick={scrollUp}
              key="2"
            >
              {dictionary.menu.gallery.sub2}
            </Link>,

            <Link
              to={`/${language}/gallery/kamczatka`}
              className={classes.dropdownLink}
              onClick={scrollUp}
              key="3"
            >
              {dictionary.menu.gallery.sub3}
            </Link>,

            <Link
              to={`/${language}/gallery/bajkal`}
              className={classes.dropdownLink}
              onClick={scrollUp}
              key="4"
            >
              {dictionary.menu.gallery.sub4}
            </Link>,

            <Link
              to={`/${language}/gallery/egipt`}
              className={classes.dropdownLink}
              onClick={scrollUp}
              key="5"
            >
              {dictionary.menu.gallery.sub5}
            </Link>,

            <Link
              to={`/${language}/gallery/orda`}
              className={classes.dropdownLink}
              onClick={scrollUp}
              key="6"
            >
              {dictionary.menu.gallery.sub6}
            </Link>,

            <Link
              to={`/${language}/gallery/kuba`}
              className={classes.dropdownLink}
              onClick={scrollUp}
              key="7"
            >
              {dictionary.menu.gallery.sub7}
            </Link>,

            <Link
              to={`/${language}/gallery/meksyk`}
              className={classes.dropdownLink}
              onClick={scrollUp}
              key="8"
            >
              {dictionary.menu.gallery.sub8}
            </Link>,

            <Link
              to={`/${language}/gallery/grenlandia`}
              className={classes.dropdownLink}
              onClick={scrollUp}
              key="9"
            >
              {dictionary.menu.gallery.sub9}
            </Link>,
          ]}
        />
      </ListItem>

      <ListItem className={classes.listItem}>
        <Button
          className={classes.navLink}
          onClick={(e) => aboutCLicked(e)}
          color="transparent"
          href={`/${language}/about`}
        >
          {dictionary.menu.about.main}
        </Button>
      </ListItem>

      {/* <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="POL"
          hoverColor="blue"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={Language}
          dropdownList={[
            <Link to="/pl" className={classes.dropdownLink}>
              polski
            </Link>,

            <Link to="/en" className={classes.dropdownLink}>
              english
            </Link>,

            <Link to="/en" className={classes.dropdownLink}>
              deutsch
            </Link>,

            <Link to="/ru" className={classes.dropdownLink}>
              русский
            </Link>
            
          ]}
        />
      </ListItem> */}
    </List>
  );
}

HeaderLinks.propTypes = {
  toggleMenuDrawer: PropTypes.func,
};
