import React, { useEffect } from "react";
import "assets/css/main.css";
import { useSelector } from "react-redux";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Typography, useMediaQuery } from "@material-ui/core";

// nodejs library that concatenates classes
import classNames from "classnames";

import Header from "components/Header/Header.js";
import Footer from "components/Footer/CustomFooter.js";

import Parallax from "components/Parallax/Parallax.js";
import CookieConsent from "react-cookie-consent";

// custom components
import ButtonUp from "components/ButtonUp/ButtonUp.js";
import { Element } from "react-scroll";
import styles from "assets/jss/material-kit-react/views/MainPage";
import logo from "assets/img/favicon.png";
import jubilee from "assets/img/banner/jubileusz.png";
import { useLanguage } from "context/TextProvider.js";
import { Outlet } from "react-router-dom";
import { applyHiddenAnimation } from "utils/applyHiddenAnimation";
import Box from "@material-ui/core/Box";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

const MainPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const banner = useSelector((state) => state.banner.banner.image);
  const isVideo = useSelector((state) => state.banner.banner.isVideo);
  const line1 = useSelector((state) => state.banner.banner.slogan);
  const line2 = useSelector((state) => state.banner.banner.slogan2);
  const classes = useStyles();
  const dictionary = useLanguage();

  useEffect(() => {
    return applyHiddenAnimation();
  }, []);

  const brandComponent = (
    <div>
      <img
        src={logo}
        alt="logo"
        width="40px"
        style={{ paddingRight: "10px" }}
      />
      <Typography variant="caption">
        office@nautilus.com.pl
        <br />
        +48 508 277 778
      </Typography>
    </div>
  );

  let bannerComponent = (
    <Parallax filter image={banner}>
      <Box
        className={classes.container}
        style={{ display: "flex", alignItems: "center" }}
      >
        <div className="banner-image-text">
          <Typography
            variant="h2"
            style={{
              fontWeight: "700",
              fontSize: `${isMobile ? 2 : 4}rem`,
            }}
          >
            {line1}
          </Typography>
          <Typography
            variant="h4"
            style={{
              fontWeight: "700",
              fontSize: `${isMobile ? 1.2 : 2}rem`,
            }}
          >
            {line2}
          </Typography>
        </div>
        <img
          src={jubilee}
          className="banner-jubilee"
          alt="jubileusz 25lat Nautilusa"
        />
      </Box>
    </Parallax>
  );

  if (isVideo && !isMobile) {
    bannerComponent = (
      <div className="banner-video-container">
        <video autoPlay="autoplay" loop="loop" muted className="banner-video">
          <source src="https://nautilus.com.pl/dive.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="banner-video-overlay"></div>
        <div className="banner-video-text">
          <Typography variant="h2" style={{ fontWeight: 600 }}>
            {line1}
          </Typography>
          <Typography variant="h4" style={{ fontWeight: 600 }}>
            {line2}
          </Typography>
        </div>
        <img
          src={jubilee}
          className="banner-jubilee"
          alt="jubileusz 25lat Nautilusa"
        />
      </div>
    );
  }

  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText={dictionary.cookie_consent.button}
        cookieName="cookieConsent"
        style={{ background: "#2B373B" }}
        buttonStyle={{
          color: "white",
          backgroundColor: "#00acc1",
          fontSize: "13px",
        }}
        expires={150}
      >
        {dictionary.cookie_consent.title}
        <br />
        <span style={{ fontSize: "10px" }}>
          {dictionary.cookie_consent.message}{" "}
        </span>
      </CookieConsent>

      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={brandComponent}
        // rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 300,
          color: "white",
        }}
      />

      {bannerComponent}

      <div
        className={classNames(classes.main, classes.mainRaised)}
        id="mobile-full-width"
      >
        <div className={classes.container}>
          <Outlet />
          <ButtonUp />
        </div>
      </div>

      <Element name="kontakt">
        <Footer />
      </Element>
    </>
  );
};

export default MainPage;
