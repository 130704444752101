import store from "utils/store.js";

export default function setSlogan(line1, line2) {
  store.dispatch({
    type: "banner/setSlogan",
    payload: line1,
  });

  store.dispatch({
    type: "banner/setSlogan2",
    payload: line2,
  });
}
