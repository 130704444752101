import axios from "axios";

const storeContact = (message, name, mail, phone, event_id) => {
  var today = new Date();
  var date =
    today.getFullYear() + "_" + (today.getMonth() + 1) + "_" + today.getDate();

  const messageJSON = {
    imie: name,
    mail: mail,
    telefon: phone,
    wiadomosc: message,
    event_id: event_id,
  };

  const url =
    "https://nautilus-contacts-default-rtdb.europe-west1.firebasedatabase.app/messages/" +
    date +
    "-" +
    name +
    ".json";
  axios
    .post(url, messageJSON)
    .then(() => {
      console.log("message stored in Firebase");
    })
    .catch((error) => {
      console.log("error while storing the message in Firebase", error);
    });
};

export default storeContact;
