import store from "utils/store.js";

export default function setEvents(events) {
  events = events.map((event) => {
    return {
      ...event,
      dateFrom: event.dateFrom.toDate().toLocaleDateString(),
      dateTo: event.dateTo.toDate().toLocaleDateString(),
      modified_on: event.modified_on.toDate().toLocaleDateString(),
      created_on: event.created_on.toDate().toLocaleDateString(),
    };
  });
  store.dispatch({
    type: "events/set",
    payload: events,
  });
}
