import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

//custom components
import CalendariumItem from "./CalendariumItem.js";
import { TextContext } from "context/TextProvider.js";

// @material-ui styles
import imagesStyles from "assets/jss/material-kit-react/imagesStyles.js";
import { cardTitle } from "assets/jss/material-kit-react.js";
import pStyles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

import { applyHiddenAnimation } from "utils/applyHiddenAnimation";
import Box from "@material-ui/core/Box";

const styles = {
  ...imagesStyles,
  ...pStyles,
  cardTitle,
};

const useStyles = makeStyles(styles);

const CalendariumItems = (props) => {
  const classes = useStyles();
  const dictionary = useContext(TextContext);
  const [filteredItems, setFilteredItems] = React.useState([]);

  useEffect(() => {
    return applyHiddenAnimation();
  }, []);

  let displayedItem = (
    <Typography
      variant="body2"
      className={`${classes.description} hidden`}
      align="left"
    >
      {dictionary.calendariumItems.text1}
      <br />
      {dictionary.calendariumItems.text2}
    </Typography>
  );

  useEffect(() => {
    setFilteredItems(
      props.kalendarium.filter(
        (item) =>
          item.status === "published" &&
          (!props.filter || item.category === props.filter)
      )
    );
  }, [props.filter, props.kalendarium]);

  if (filteredItems.length > 0) {
    displayedItem = (
      <div>
        <Box className="hidden left" style={{ marginBottom: "20px" }}>
          <Typography
            variant="body1"
            className={classes.description}
            style={{ marginBottom: "20px" }}
          >
            {dictionary.calendariumItems.text3}
            <br />
            {dictionary.calendariumItems.text4}
          </Typography>
        </Box>
        <GridContainer justifyContent="flex-start" className="delayed">
          {filteredItems.map((item) => {
            return (
              <GridItem
                xs={12}
                sm={12}
                md={4}
                key={item.eventId}
                // className="hidden"
              >
                <CalendariumItem
                  event={item}
                  eventId={item.eventId}
                  // className="hidden"
                />
              </GridItem>
            );
          })}
        </GridContainer>
      </div>
    );
  }

  return filteredItems ? (
    <div className={classes.section}>
      <Box className="left">
        <Typography
          variant="h4"
          className={`${classes.description} hidden`}
          style={{ marginBottom: "30px", fontWeight: "600" }}
        >
          {dictionary.calendariumItems.text5}
        </Typography>
      </Box>

      {displayedItem}
    </div>
  ) : null;
};

CalendariumItems.propTypes = {
  kalendarium: PropTypes.array,
  filter: PropTypes.string,
};

export default CalendariumItems;
