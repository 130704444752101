import { init, send } from "emailjs-com";

init("user_nWS0vJp8uZUIDLEKVyl5m");

function sendMail(
  setShowSpinner,
  setShowModal,
  setModalMode,
  setModalTitle,
  setModalMessage,
  sendCopy,
  variables
) {
  setShowSpinner(true);

  const templateId = "template_6l6b3yh";
  const copyTemplateId = "template_43fm1cq";
  const serviceId = "service_8clzllj";

  send(serviceId, templateId, variables)
    .then(() => {
      setModalMode("success");
      setModalTitle("Wiadomość wysłana!");
      setModalMessage(
        "Dziękujemy za wiadomość. Wkrótce skontaktujemy się z Tobą."
      );
      setShowModal(true);
      setShowSpinner(false);
    })
    .catch((err) => {
      setModalMode("danger");
      setModalTitle("Nie udało się wysłać wiadomości!");
      setModalMessage(err);
      setShowModal(true);
      setShowSpinner(false);
    });

  if (sendCopy) {
    send(serviceId, copyTemplateId, variables)
      .then(() => {
        console.log("the copy message send succesfully");
      })
      .catch((err) => {
        console.log("error while sending the copy message.", err);
      });
  }
}

export default sendMail;
