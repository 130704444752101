import store from "utils/store.js";

function sorter(a, b) {
  if (a.created_on > b.created_on) {
    return -1;
  }
  if (a.created_on < b.created_on) {
    return 1;
  }
  return 0;
}

function filtrator(blogEntry) {
  return blogEntry.status === "published";
}

export default function setBlog(blog) {
  blog = blog
    .filter(filtrator)
    .sort(sorter)
    .map((blogEntry) => {
      return {
        ...blogEntry,
        modified_on: blogEntry.modified_on.toDate().toLocaleDateString(),
        created_on: blogEntry.created_on.toDate().toLocaleDateString(),
      };
    });
  store.dispatch({
    type: "blog/set",
    payload: blog,
  });
}
