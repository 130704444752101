import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.js";
import Close from "@material-ui/icons/Close";
import styles from "assets/jss/material-kit-react/views/componentsSections/javascriptStyles.js";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props) {
  return <Slide direction="down" {...props} />;
});

Transition.displayName = "Transition";

const Modal = (props) => {
  function modalClose() {
    props.setShowModal(false);
    if (props.modalClosed) {
      props.modalClosed();
    }
  }

  const classes = useStyles();

  let color = "info";

  if (props.mode === "success") {
    color = "success";
  } else if (props.mode === "danger") {
    color = "danger";
  }

  let buttonText = "zamknij";

  if (props.buttonText) {
    buttonText = props.buttonText;
  }

  return (
    <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal,
      }}
      open={props.showModal}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => modalClose()}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <IconButton
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => modalClose()}
        >
          <Close className={classes.modalClose} />
        </IconButton>

        <h4 className={classes.modalTitle}>{props.title}</h4>
      </DialogTitle>

      <DialogContent
        id="classic-modal-slide-description"
        className={classes.modalBody}
      >
        <p>{props.message}</p>
      </DialogContent>

      <DialogActions className={classes.modalFooter}>
        <Button onClick={() => modalClose()} color={color} simple>
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Modal.propTypes = {
  setShowModal: PropTypes.func,
  modalClosed: PropTypes.func,
  showModal: PropTypes.bool,
  mode: PropTypes.string,
  buttonText: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
};

export default Modal;
