import React, { forwardRef, memo } from "react";
import { useCMS } from "../../utils/useCMS";
import "./video.css";

export const Video = memo(
  forwardRef((props, ref) => {
    // eslint-disable-next-line react/prop-types
    const { src, id, className, imageClassName, onClick, style } = props;
    const { getImageUrl } = useCMS();
    const url = getImageUrl(src);
    const [imageLoaded, setImageLoaded] = React.useState(false);
    const localRef = React.useRef();
    ref = ref || localRef;

    return (
      <div
        className={"image_block " + className}
        onClick={onClick}
        style={style}
      >
        {!imageLoaded && <div className="image_block_loader" />}
        <iframe
          id={id}
          ref={ref}
          className={"section_image " + imageClassName}
          width="100%"
          height="100%"
          src={url}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          onLoad={() => setImageLoaded(true)}
        ></iframe>
      </div>
    );
  })
);

Video.displayName = "Image";
