import React, { Component } from "react";
import PropTypes from "prop-types";
import CalendariumItems from "./CalendariumItems.js";
import Splitter from "../../../../components/Splitter/Splitter";

class Calendarium extends Component {
  render() {
    let displayedItem = null;

    if (this.props.events) {
      displayedItem = (
        <CalendariumItems
          filter={this.props.filter}
          kalendarium={this.props.events}
        />
      );
    }

    return (
      <div style={{ paddingBottom: "50px" }}>
        <Splitter />
        {displayedItem}
      </div>
    );
  }
}

Calendarium.propTypes = {
  events: PropTypes.array,
  filter: PropTypes.string,
};

export default Calendarium;
